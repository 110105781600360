import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { EditRequestModalService } from '../../edit-request-modal/edit-request-modal.service';
import {$e} from "@angular/compiler/src/chars";

@Component({
  selector: 'app-request-section-list-item',
  templateUrl: './request-section-list-item.component.html',
  styleUrls: ['./request-section-list-item.component.scss'],
})
export class RequestSectionListItemComponent {
  @Input() sectionCollection!: FormGroup;
  @Input() sectionCollectionIndex!: number;
  @Input() sectionIndex!: number;

  constructor(public editRequestModalService: EditRequestModalService) {}

  removeSection() {
    (this.sectionCollection.get('sections') as FormArray).removeAt(
      this.sectionIndex,
      {
        emitEvent: true,
      }
    );
    if (this.sectionCollectionIndex === this.editRequestModalService.selectedCollectionIndex && this.sectionIndex === this.editRequestModalService.selectedSectionIndex) {
      this.editRequestModalService.selectedCollectionIndex = 0;
      this.editRequestModalService.selectedSectionIndex = 0;
    }
  }

  get isSelected() {
    return (
      this.editRequestModalService.selectedCollectionIndex ===
        this.sectionCollectionIndex &&
      this.editRequestModalService.selectedSectionIndex === this.sectionIndex
    );
  }

  setAsSelected(e: MouseEvent) {
    e.stopPropagation();
    this.editRequestModalService.selectedCollectionIndex =
      this.sectionCollectionIndex;
    this.editRequestModalService.selectedSectionIndex = this.sectionIndex;
  }

  get currentSection() {
    return (this.sectionCollection.get('sections') as FormArray).controls[
      this.sectionIndex
    ] as FormGroup;
  }

  get currentSectionTitle() {
    return this.currentSection.get('title')?.value;
  }
}
