<app-custom-modal
  [modalEvents]="editClientModalService.modalEvents"
  classes="max-w-4xl font-Satoshi"
  *transloco="let t"
  maxHeight="600px"
>
  <h3 header class="font-Montserrat text-xl font-bold">
    <span
      *ngIf="editClientModalService.currentClientId && isProducer"
      transloco="Produzent Bearbeiten"
      >Producer Bearbeiten</span
    >
    <span
      *ngIf="!editClientModalService.currentClientId && isProducer"
      transloco="Neuer Produzent"
      >Neuer Produzent</span
    >
    <span
      *ngIf="editClientModalService.currentClientId && !isProducer"
      transloco="Kunde Bearbeiten"
      >Kunde Bearbeiten</span
    >
    <span
      *ngIf="!editClientModalService.currentClientId && !isProducer"
      transloco="Neuer Kunde"
      >Neuer Kunde</span
    >
  </h3>
  <div
    content-body
    class="w-full flex flex-col items-center justify-center pb-6 text-center"
    *transloco="let t"
  >
    <form class="w-full mb-4" [formGroup]="editClientModalService.clientForm">
      <div class="flex flex-col items-start w-full my-4" *isSuperAdmin>
        <label
          for="customer"
          class="block mb-2 font-bold text-base text-primary"
          transloco="Kunde"
          >Kunde</label
        >
        <select
          id="customer"
          class="border text-xs 2xl:text-sm rounded-lg focus:border-gray15 focus:ring-1 focus:ring-gray15 focus:outline-none block w-full p-2.5"
          [ngClass]="
            editClientModalService.customerIdControl.touched &&
            editClientModalService.customerIdControl.errors
              ? 'bg-red-50 border-red-500 text-red-900'
              : 'bg-gray-50 border-gray-300  text-gray-900'
          "
          [formControl]="editClientModalService.customerIdControl"
        >
          <option
            [value]="customer.id"
            *ngFor="let customer of editClientModalService.customerList"
          >
            {{ customer.label }}
          </option>
        </select>
      </div>
      <div class="flex flex-col items-start w-full my-4">
        <label
          for="company_name"
          class="block mb-2 font-bold text-base text-primary"
          transloco="Company Name"
          >Company Name</label
        >
        <input
          type="text"
          id="company_name"
          [ngClass]="
            editClientModalService.companyNameControl.touched &&
            editClientModalService.companyNameControl.errors
              ? 'bg-red-50 border-red-500 text-red-900'
              : 'bg-gray-50 border-gray-300  text-gray-900'
          "
          class="bg-gray-50 border border-gray-300 text-gray-900 text-xs 2xl:text-sm rounded-lg focus:border-gray15 focus:ring-1 focus:ring-gray15 focus:outline-none block w-full p-2.5"
          [placeholder]="t('Enter company name')"
          formControlName="company_name"
        />
      </div>
      <div
        class="mt-2 text-xs 2xl:text-sm text-red-600 dark:text-red-500"
        *ngIf="
          editClientModalService.companyNameControl.touched &&
          editClientModalService.companyNameControl.errors
        "
      >
        <span
          *ngIf="editClientModalService.companyNameControl.hasError('required')"
          transloco="Required"
          >Required</span
        >
        <span
          *ngIf="
            editClientModalService.companyNameControl.hasError('maxlength')
          "
          transloco="Max 255 characters allowed"
          >Max 255 characters allowed</span
        >
      </div>
    </form>
    <div
      class="flex items-center p-3 2xl:p-4 mb-4 text-xs 2xl:text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 w-full"
      role="alert"
      *ngIf="
        editClientModalService.showContactRequiredErr &&
        !editClientModalService.contacts.length
      "
    >
      <svg
        class="flex-shrink-0 inline w-4 h-4 me-3"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 20 20"
      >
        <path
          d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"
        />
      </svg>
      <span class="sr-only" transloco="Info">Info</span>
      <div transloco="At least one contact is required!">
        At least one contact is required!
      </div>
    </div>
    <div class="flex justify-between w-full items-center mt-8">
      <div
        class="block mb-2 font-bold text-base text-primary"
        transloco="Ansprechpartner"
      >
        Ansprechpartner
      </div>
      <button
        class="tw-btn-blue"
        transloco="+ Neuer Ansprechpartner"
        (click)="addContact()"
      >
        + Neuer Ansprechpartner
      </button>
    </div>
    <div class="mt-5 w-full">
      <table
        *ngIf="editClientModalService.contacts.length"
        class="w-full text-left text-gray-500"
      >
        <thead class="text-black text-sm font-bold">
          <tr>
            <th
              scope="col"
              class="border border-gray3 px-4 py-3"
              transloco="Salutation"
            >
              Salutation
            </th>
            <th
              scope="col"
              class="border border-gray3 px-4 py-3 w-1/5"
              transloco="Firstname"
            >
              Firstname
            </th>
            <th
              scope="col"
              class="border border-gray3 px-4 py-3 w-1/5"
              transloco="Lastname"
            >
              Lastname
            </th>
            <th
              scope="col"
              class="border border-gray3 px-4 py-3 w-1/5"
              transloco="E-Mail"
            >
              E-Mail
            </th>
            <th
              scope="col"
              class="border border-gray3 px-4 py-3 w-1/5"
              transloco="Aktionen"
            >
              Aktionen
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="pt-2"></td>
          </tr>
          <tr
            *ngFor="
              let contact of editClientModalService.contacts;
              let idx = index
            "
            class="bg-gray10 text-sm hover:bg-gray-200"
            data-expanded="false"
          >
            <td class="px-4 py-2 text-left">
              {{ contact.salutation }}
            </td>
            <td class="px-4 py-2 text-left">
              {{ contact.first_name }}
            </td>
            <td class="px-4 py-2 text-left">
              {{ contact.last_name }}
            </td>
            <td class="px-4 py-2 text-left">
              {{ contact.email }}
            </td>
            <td
              class=" px-4   py-2 text-left {{
                !editClientModalService.currentClientId
              }}"
            >
              <div
                class="bg-white rounded-lg text-sm font-medium flex items-center px-4"
              >
                <button
                  (click)="editContact(contact.id || idx)"
                  class="tw-btn-ghost py-[6px] px-0"
                  transloco="Edit"
                >
                  Edit
                </button>
                <div class="border-gray3 border-r-2 h-[22px] w-1 mx-2"></div>
                <button
                  (click)="initDeleteContact(contact.id || idx)"
                  class="tw-btn-ghost py-[6px] px-0"
                  transloco="Delete"
                >
                  Delete
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div footer class="w-full justify-between flex">
    <button
      type="button"
      (click)="initClientModalClose()"
      [disabled]="editClientModalService.clientForm.disabled"
      class="tw-btn-ghost bg-white min-w-[150px]"
      transloco="Cancel"
    >
      Cancel
    </button>
    <button
      *ngIf="editClientModalService.currentClientId"
      type="button"
      [disabled]="editClientModalService.clientForm.disabled"
      class="tw-btn-blue min-w-[150px]"
      (click)="initDeleteClient()"
      transloco="Delete"
    >
      Delete
    </button>
    <button
      type="button"
      (click)="editClientModalService.handleClientFormSubmit()"
      [disabled]="editClientModalService.clientForm.disabled"
      class="tw-btn-secondary min-w-[150px]"
      transloco="Save"
    >
      Save
    </button>
  </div>
</app-custom-modal>

<app-custom-modal
  [modalEvents]="editClientModalService.contactModalEvents"
  classes="max-w-4xl font-Satoshi"
  *transloco="let t"
  maxHeight="600px"
  [formGroup]="editClientModalService.contactForm"
>
  <h3 header class="font-Montserrat text-xl font-bold">
    <span
      *ngIf="editClientModalService.currentContactId !== undefined"
      transloco="Edit Contact"
      >Edit Contact</span
    >
    <span
      *ngIf="editClientModalService.currentContactId === undefined"
      transloco="New Contact"
      >New Contact</span
    >
  </h3>
  <div content-body class="w-full">
    <div class="mt-4">
      <label
        for="salutation"
        class="block mb-2 font-bold text-base text-primary"
        transloco="Salutation"
        >Salutation</label
      >
      <select
        id="salutation"
        class="border text-xs 2xl:text-sm rounded-lg focus:border-gray15 focus:ring-1 focus:ring-gray15 focus:outline-none block w-full p-2.5"
        [ngClass]="
          editClientModalService.salutationControl.touched &&
          editClientModalService.salutationControl.errors
            ? 'bg-red-50 border-red-500 text-red-900'
            : 'bg-gray-50 border-gray-300  text-gray-900'
        "
        formControlName="salutation"
      >
        <option value="Herr" transloco="Mr">Mr</option>
        <option value="Frau" transloco="Ms">Ms</option>
        <option value="Divers" transloco="Other">Other</option>
      </select>
      <div
        class="mt-2 text-xs 2xl:text-sm text-red-600 dark:text-red-500"
        *ngIf="
          editClientModalService.salutationControl.touched &&
          editClientModalService.salutationControl.errors
        "
        transloco="Required"
      >
        Required
      </div>
    </div>
    <div class="flex w-full space-x-3">
      <div class="mt-4 w-full">
        <label
          for="first_name"
          class="block mb-2 font-bold text-base text-primary"
          transloco="Firstname"
          >Firstname</label
        >
        <input
          type="text"
          id="first_name"
          class="border text-xs 2xl:text-sm rounded-lg focus:border-gray15 focus:ring-1 focus:ring-gray15 focus:outline-none block w-full p-2.5"
          [ngClass]="
            editClientModalService.firstNameControl.touched &&
            editClientModalService.firstNameControl.errors
              ? 'bg-red-50 border-red-500 text-red-900'
              : 'bg-gray-50 border-gray-300  text-gray-900'
          "
          [placeholder]="t('Enter firstname')"
          formControlName="first_name"
        />
        <div
          class="mt-2 text-xs 2xl:text-sm text-red-600 dark:text-red-500"
          *ngIf="
            editClientModalService.firstNameControl.touched &&
            editClientModalService.firstNameControl.errors
          "
        >
          <span
            *ngIf="editClientModalService.firstNameControl.hasError('required')"
            transloco="Required"
            >Required</span
          >
          <span
            *ngIf="
              editClientModalService.firstNameControl.hasError('maxlength')
            "
            transloco="Max 255 characters allowed"
            >Max 255 characters allowed</span
          >
        </div>
      </div>
      <div class="mt-4 w-full">
        <label
          for="last_name"
          class="block mb-2 font-bold text-base text-primary"
          transloco="Lastname"
          >Lastname</label
        >
        <input
          type="text"
          id="last_name"
          class="border text-xs 2xl:text-sm rounded-lg focus:border-gray15 focus:ring-1 focus:ring-gray15 focus:outline-none block w-full p-2.5"
          [ngClass]="
            editClientModalService.lastNameControl.touched &&
            editClientModalService.lastNameControl.errors
              ? 'bg-red-50 border-red-500 text-red-900'
              : 'bg-gray-50 border-gray-300  text-gray-900'
          "
          [placeholder]="t('Enter lastname')"
          formControlName="last_name"
        />
        <div
          class="mt-2 text-xs 2xl:text-sm text-red-600 dark:text-red-500"
          *ngIf="
            editClientModalService.lastNameControl.touched &&
            editClientModalService.lastNameControl.errors
          "
        >
          <span
            *ngIf="editClientModalService.lastNameControl.hasError('required')"
            transloco="Required"
            >Required</span
          >
          <span
            *ngIf="editClientModalService.lastNameControl.hasError('maxlength')"
            transloco="Max 255 characters allowed"
            >Max 255 characters allowed</span
          >
        </div>
      </div>
    </div>
    <div class="mt-4">
      <label
        for="email"
        class="block mb-2 font-bold text-base text-primary"
        transloco="E-Mail"
        >E-Mail</label
      >
      <input
        type="text"
        id="email"
        class="border text-xs 2xl:text-sm rounded-lg focus:border-gray15 focus:ring-1 focus:ring-gray15 focus:outline-none block w-full p-2.5"
        [ngClass]="
          editClientModalService.emailControl.touched &&
          editClientModalService.emailControl.errors
            ? 'bg-red-50 border-red-500 text-red-900'
            : 'bg-gray-50 border-gray-300  text-gray-900'
        "
        [placeholder]="t('Enter email')"
        formControlName="email"
      />
      <div
        class="mt-2 text-xs 2xl:text-sm text-red-600 dark:text-red-500"
        *ngIf="
          editClientModalService.emailControl.touched &&
          editClientModalService.emailControl.errors
        "
      >
        <span
          *ngIf="editClientModalService.emailControl.hasError('required')"
          transloco="Required"
          >Required</span
        >
        <span
          *ngIf="editClientModalService.emailControl.hasError('email')"
          transloco="Invalid email"
          >Invalid email</span
        >
        <span
          *ngIf="editClientModalService.emailControl.hasError('maxlength')"
          transloco="Max 255 characters allowed"
          >Max 255 characters allowed</span
        >
      </div>
    </div>
  </div>
  <div footer class="flex justify-between w-full items-center">
    <button
      (click)="initContactModalClose()"
      type="button"
      class="tw-btn-ghost bg-white min-w-[150px]"
      transloco="Cancel"
    >
      Cancel
    </button>
    <button
      *ngIf="editClientModalService.currentContactId !== undefined"
      type="button"
      class="tw-btn-blue min-w-[150px]"
      (click)="initDeleteContact(editClientModalService.currentContactId)"
      transloco="Delete"
    >
      Delete
    </button>
    <button
      type="button"
      class="tw-btn-secondary min-w-[150px]"
      (click)="editClientModalService.handleContactFormSubmit()"
      transloco="Save"
    >
      Save
    </button>
  </div>
</app-custom-modal>

<app-custom-modal classes="max-w-lg" [modalEvents]="closeConfirmModalEvents">
  <h3 header class="text-xl font-semibold" transloco="Confirmation">
    Confirmation
  </h3>
  <div
    content-body
    class="w-full flex flex-col items-center justify-center py-6 text-center"
    *transloco="let t"
  >
    <svg
      class="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
      />
    </svg>
    <h3
      class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400"
      transloco="Are you sure you want to cancel?"
    >
      Are you sure you want to cancel?
    </h3>
  </div>
  <div footer class="w-full justify-between flex">
    <button
      type="button"
      class="tw-btn-ghost bg-white min-w-[130px]"
      transloco="No"
      (click)="hideCloseConfirmModal()"
    >
      No
    </button>
    <button
      type="button"
      class="tw-btn-secondary text-white min-w-[130px]"
      transloco="Yes"
      (click)="handleConfirmCloseClick()"
    >
      Yes
    </button>
  </div>
</app-custom-modal>
<app-custom-modal
  [modalEvents]="editClientModalService.deleteConfirmModalEvents"
  classes="max-w-lg"
>
  <h1 header class="text-xl font-semibold" transloco="Delete Confirmation">
    Delete Confirmation
  </h1>
  <div
    content-body
    class="w-full flex flex-col items-center justify-center py-6 text-center"
    *transloco="let t"
  >
    <svg
      class="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
      />
    </svg>
    <h3
      class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400"
      transloco=" Are you sure you want to delete this client and all of their data?"
      *ngIf="editClientModalService.deleteConfirmType === 'client'"
    >
      Are you sure you want to delete this client and all of their data?
    </h3>
    <h3
      class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400"
      *ngIf="editClientModalService.deleteConfirmType === 'contact'"
      transloco="Are you sure you want to remove this contact?"
    >
      Are you sure you want to remove this contact?
    </h3>
  </div>
  <div footer class="w-full justify-between flex">
    <button
      type="button"
      class="tw-btn-ghost bg-white min-w-[130px]"
      transloco="No"
      (click)="editClientModalService.closeDeleteConfirmModal()"
    >
      No
    </button>
    <button
      type="button"
      class="tw-btn-ghost bg-semantic-danger-500 text-white min-w-[130px]"
      transloco="Yes"
      (click)="editClientModalService.handleDeleteConfirm()"
    >
      Yes
    </button>
  </div>
</app-custom-modal>
