<div class="container mx-auto font-Satoshi">
  <div class="w-full flex items-center justify-between flex-wrap gap-2">
    <div *ngIf="!currentFolderId">
      <h1
        class="text-2xl font-bold text-primary font-Montserrat"
        transloco="Requests"
      >
        Requests
      </h1>
    </div>
    <div *ngIf="currentFolderId" class="flex items-center gap-4">
      <div
        class="w-20 h-20 border-gray3 flex items-center justify-center border rounded-lg"
      >
        <svg
          width="43"
          height="32"
          viewBox="0 0 43 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M41.9375 11.745C41.6591 11.359 41.2928 11.0448 40.869 10.8283C40.4452 10.6118 39.9759 10.4993 39.5 10.5H36.5V7.5C36.5 6.70435 36.1839 5.94129 35.6213 5.37868C35.0587 4.81607 34.2957 4.5 33.5 4.5H20.5006L15.3013 0.6C14.7811 0.212217 14.1501 0.00187504 13.5012 0H3.5C2.70435 0 1.94129 0.31607 1.37868 0.87868C0.81607 1.44129 0.5 2.20435 0.5 3V30C0.5 30.3978 0.658035 30.7794 0.93934 31.0607C1.22064 31.342 1.60218 31.5 2 31.5H35.5813C35.8961 31.5 36.2029 31.401 36.4584 31.2169C36.7138 31.0328 36.9048 30.7731 37.0044 30.4744L42.3462 14.4487C42.4965 13.9978 42.5377 13.5177 42.4667 13.0478C42.3957 12.5778 42.2143 12.1314 41.9375 11.745ZM13.5012 3L18.7006 6.9C19.2207 7.28802 19.8518 7.49839 20.5006 7.5H33.5V10.5H9.08187C8.4522 10.5 7.83848 10.698 7.32764 11.0662C6.8168 11.4343 6.43476 11.9539 6.23563 12.5513L3.5 20.7562V3H13.5012ZM34.5013 28.5H4.08125L9.08187 13.5H39.5L34.5013 28.5Z"
            fill="black"
          />
        </svg>
      </div>
      <div class="flex flex-col" *ngIf="currentFolderData">
        <div class="flex items-center gap-1">
          <h1 class="text-2xl font-bold text-primary">
            {{ currentFolderData?.title }}
          </h1>
          <button
            class="font-semibold text-primary text-xs 2xl:text-sm transition-all duration-200 hover:bg-gray-100 rounded-full p-2"
            (click)="openFolderEditModal()"
          >
            <img src="assets/imgs/icons/edit-icon.svg" alt="edit" />
          </button>
        </div>
        <ul class="flex items-center gap-2">
          <li>
            <a href="/requests">
              <svg
                width="6"
                height="10"
                viewBox="0 0 6 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0.528764 5.47157C0.403783 5.34655 0.333572 5.17702 0.333572 5.00024C0.333572 4.82346 0.403783 4.65392 0.528764 4.52891L4.3001 0.757572C4.36159 0.693899 4.43516 0.643111 4.51649 0.608171C4.59783 0.573232 4.68531 0.554841 4.77383 0.554072C4.86235 0.553303 4.95014 0.570171 5.03207 0.603691C5.114 0.637212 5.18843 0.686714 5.25103 0.749309C5.31362 0.811904 5.36312 0.886339 5.39664 0.968269C5.43017 1.0502 5.44703 1.13799 5.44626 1.22651C5.44549 1.31503 5.4271 1.40251 5.39216 1.48384C5.35723 1.56518 5.30644 1.63874 5.24276 1.70024L1.94276 5.00024L5.24276 8.30024C5.3642 8.42598 5.4314 8.59438 5.42988 8.76917C5.42836 8.94397 5.35825 9.11118 5.23464 9.23479C5.11104 9.35839 4.94383 9.4285 4.76903 9.43002C4.59423 9.43154 4.42583 9.36435 4.3001 9.24291L0.528764 5.47157Z"
                  fill="black"
                />
              </svg>
            </a>
          </li>
          <li class="font-normal text-xs text-gray4">
            <a routerLink="/requests" transloco="Requests">Requests</a>
          </li>
          <li
            class="font-semibold text-xs 2xl:text-small"
            [ngClass]="{ 'text-primary': last, 'text-gray4': !last }"
            *ngFor="let pathItem of currentFolderData.path; let last = last"
          >
            <div *ngIf="last" class="font-semibold">
              / {{ pathItem?.title }}
            </div>
            <a
              [routerLink]="'/requests/folders/' + pathItem.id"
              *ngIf="!last"
              class="font-normal text-xs text-gray4"
            >
              / {{ pathItem?.title }}</a
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="flex gap-4">
      <a
        type="button"
        [href]="
          currentFolderId
            ? '/requests/new?request_folder_id=' + currentFolderId
            : '/requests/new'
        "
        class="tw-btn-blue"
        transloco="+ New Request"
      >
        + New Request
      </a>
      <button
        type="button"
        (click)="openCreateFolderModal()"
        class="tw-btn-secondary"
        transloco="+ New Folder"
      >
        + New Folder
      </button>
    </div>
  </div>
  <div class="w-full flex mt-6 flex-wrap gap-4 items-center" *transloco="let t">
    <div class="relative min-w-[380px] flex-grow">
      <input
        type="search"
        [formControl]="searchControl"
        id="default-search"
        class="block w-full p-3 2xl:p-4 pe-14 text-xs 2xl:text-sm text-gray-900 border border-gray-300 rounded-lg focus:border-gray15 focus:ring-1 focus:ring-gray15 focus:outline-none"
        [placeholder]="t('Search by folder, name or company...')"
      />
      <button
        (click)="doSearch()"
        class="absolute right-0.5 top-1/2 -translate-y-1/2 rounded-lg px-4 py-2"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M20.9992 21L16.6992 16.7"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </div>
    <div>
      <app-custom-dropdown [hasRawContent]="true">
        <button
          rawButton
          class="border border-gray-300 rounded-lg p-3 2xl:p-4 items-center font-medium text-gray2 hover:opacity-90 text-xs 2xl:text-sm min-w-[320px] flex bg-white"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            class="mr-2"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 5.6C20 5.04 20 4.76 19.89 4.546C19.7945 4.35792 19.6419 4.20493 19.454 4.109C19.24 4 18.96 4 18.4 4H5.6C5.04 4 4.76 4 4.546 4.109C4.35785 4.20487 4.20487 4.35785 4.109 4.546C4 4.76 4 5.04 4 5.6V6.337C4 6.582 4 6.704 4.028 6.819C4.05249 6.92138 4.09299 7.01925 4.148 7.109C4.209 7.209 4.296 7.296 4.468 7.469L9.531 12.531C9.704 12.704 9.791 12.791 9.852 12.891C9.907 12.981 9.948 13.079 9.972 13.181C10 13.295 10 13.416 10 13.655V18.411C10 19.268 10 19.697 10.18 19.955C10.2581 20.0666 10.3582 20.161 10.4741 20.2326C10.59 20.3041 10.7192 20.3512 10.854 20.371C11.165 20.417 11.549 20.226 12.315 19.842L13.115 19.442C13.437 19.282 13.597 19.202 13.714 19.082C13.8178 18.976 13.8967 18.8483 13.945 18.708C14 18.55 14 18.37 14 18.011V13.663C14 13.418 14 13.296 14.028 13.181C14.0525 13.0786 14.093 12.9807 14.148 12.891C14.208 12.791 14.295 12.705 14.465 12.535L14.469 12.531L19.532 7.469C19.704 7.296 19.79 7.209 19.852 7.109C19.9071 7.0193 19.9476 6.92141 19.972 6.819C20 6.706 20 6.584 20 6.345V5.6Z"
              stroke="#8091A7"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <div transloco="Status" class="font-semibold text-sm">Status:</div>
          <div class="text-black font-semibold text-sm ml-2">
            {{ selectedStatusTitle }}
          </div>
          <div class="grow flex-grow-1 flex justify-end">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_17_845)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12.7083 15.707C12.5208 15.8944 12.2665 15.9998 12.0013 15.9998C11.7362 15.9998 11.4818 15.8944 11.2943 15.707L5.63732 10.05C5.54181 9.95773 5.46563 9.84739 5.41322 9.72538C5.36081 9.60338 5.33322 9.47216 5.33207 9.33938C5.33092 9.2066 5.35622 9.07492 5.4065 8.95202C5.45678 8.82913 5.53103 8.71747 5.62492 8.62358C5.71882 8.52969 5.83047 8.45544 5.95337 8.40515C6.07626 8.35487 6.20794 8.32957 6.34072 8.33073C6.4735 8.33188 6.60472 8.35947 6.72672 8.41188C6.84873 8.46428 6.95907 8.54047 7.05132 8.63598L12.0013 13.586L16.9513 8.63598C17.1399 8.45382 17.3925 8.35302 17.6547 8.3553C17.9169 8.35758 18.1677 8.46275 18.3531 8.64816C18.5385 8.83357 18.6437 9.08438 18.646 9.34658C18.6483 9.60877 18.5475 9.86137 18.3653 10.05L12.7083 15.707Z"
                  fill="black"
                />
              </g>
              <defs>
                <clipPath id="clip0_17_845">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </button>
        <ng-container>
          <ul
            class="p-3 space-y-1 text-xs 2xl:text-sm text-gray-700 bg-white"
            aria-labelledby="dropdownRadioButton"
          >
            <li>
              <div
                class="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600"
              >
                <input
                  id="filter-radio-all"
                  type="radio"
                  value="all"
                  [formControl]="statusFilterControl"
                  name="filter-radio"
                  class="w-4 h-4 text-primaryBtn bg-gray-100 border-gray-300 focus:ring-primaryBtn dark:focus:ring-primaryBtn dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  for="filter-radio-all"
                  class="w-full ms-2 text-lg font-medium text-gray-900 rounded dark:text-gray-300"
                  transloco="All Status"
                  >All Status</label
                >
              </div>
            </li>
            <ng-container *ngFor="let item of status_list">
              <li>
                <div
                  class="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600"
                >
                  <input
                    id="{{ item.id }}"
                    type="radio"
                    value="{{ item.id }}"
                    [formControl]="statusFilterControl"
                    name="filter-radio"
                    class="w-4 h-4 text-primaryBtn bg-gray-100 border-gray-300 focus:ring-primaryBtn dark:focus:ring-primaryBtn dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    for="{{ item.id }}"
                    class="w-full ms-2 text-lg font-medium text-gray-900 rounded dark:text-gray-300"
                    >{{ t(item.title) }}</label
                  >
                </div>
              </li>
            </ng-container>
          </ul>
        </ng-container>
      </app-custom-dropdown>
    </div>
  </div>
  <div
    class="overflow-x-auto shadow-md sm:rounded-lg min-h-[500px]"
    *transloco="let t"
  >
    <table class="w-full mt-4 text-xs 2xl:text-sm text-left text-gray-500">
      <thead class="text-xs 2xl:text-sm text-black font-semibold bg-white">
        <tr>
          <th
            scope="col"
            class="border-l border-t border-b border-gray3 px-6 py-3"
          >
            <app-sortable-table-header
              displayName="#"
              fieldName="project_number"
            >
            </app-sortable-table-header>
          </th>
          <th
            scope="col"
            class="border-t border-b border-gray3 px-6 py-3 rounded-l"
          >
            <app-sortable-table-header
              [displayName]="t('Name')"
              fieldName="title"
            >
            </app-sortable-table-header>
          </th>
          <th
            scope="col"
            class="border-t border-b border-gray3 px-6 py-3 w-1/7"
          >
            <app-sortable-table-header
              [displayName]="t('Client')"
              fieldName="client_id"
            >
            </app-sortable-table-header>
          </th>
          <th
            scope="col"
            class="border-t border-b border-gray3 px-2 py-3 w-1/7"
          >
            <app-sortable-table-header
              [displayName]="t('Status')"
              fieldName="status"
            >
            </app-sortable-table-header>
          </th>
          <th
            scope="col"
            class="border-t border-b border-gray3 px-2 py-3 break-all"
            [ngClass]="{
              'whitespace-nowrap': translocoService.getActiveLang() === 'en'
            }"
          >
            <app-sortable-table-header
              [displayName]="t('Due Date')"
              fieldName="due_date"
            >
            </app-sortable-table-header>
          </th>
          <th
            scope="col"
            class="border-t border-b border-gray3 px-2 py-3 w-1/7 break-all"
            [ngClass]="{
              'whitespace-nowrap': translocoService.getActiveLang() === 'en'
            }"
          >
            <app-sortable-table-header
              [displayName]="t('Updated At')"
              fieldName="updated_at"
            >
            </app-sortable-table-header>
          </th>
          <th
            scope="col"
            class="border-t border-b border-gray3 px-2 py-3 w-1/7 whitespace-nowrap break-all"
            [ngClass]="{
              'whitespace-nowrap': translocoService.getActiveLang() === 'en'
            }"
          >
            <app-sortable-table-header
              [displayName]="t('Created At')"
              fieldName="created_at"
            >
            </app-sortable-table-header>
          </th>
          <th
            scope="col"
            class="border-t border-b border-gray3 px-2 py-3"
            transloco="File"
          >
            File
          </th>
          <th
            scope="col"
            class="border-t border-b border-r rounded-r border-gray3 px-2 py-3 text-center"
            transloco="Actions"
          >
            Actions
          </th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let data of requestFolders">
          <tr
            class="font-bold text-base text-primary border-b hover:bg-gray18 border-white"
            data-expanded="false"
            id="{{ data.id }}"
          >
            <td></td>
            <td
              colspan="7"
              class="px-6 py-4 whitespace-nowrap truncate cursor-pointer"
            >
              <a
                class="flex gap-2 items-center w-full"
                [routerLink]="'/requests/folders/' + data.id"
              >
                <svg
                  width="22"
                  height="17"
                  viewBox="0 0 22 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.9688 6.3725C20.8295 6.17951 20.6464 6.0224 20.4345 5.91416C20.2226 5.80592 19.988 5.74965 19.75 5.75H18.25V4.25C18.25 3.85218 18.092 3.47064 17.8107 3.18934C17.5294 2.90804 17.1478 2.75 16.75 2.75H10.2503L7.65063 0.8C7.39054 0.606109 7.07503 0.500938 6.75062 0.5H1.75C1.35218 0.5 0.970644 0.658035 0.68934 0.93934C0.408035 1.22064 0.25 1.60218 0.25 2V15.5C0.25 15.6989 0.329018 15.8897 0.46967 16.0303C0.610322 16.171 0.801088 16.25 1 16.25H17.7906C17.948 16.25 18.1015 16.2005 18.2292 16.1085C18.3569 16.0164 18.4524 15.8865 18.5022 15.7372L21.1731 7.72437C21.2482 7.49891 21.2689 7.25886 21.2334 7.02388C21.1978 6.78891 21.1072 6.56569 20.9688 6.3725ZM6.75062 2L9.35031 3.95C9.61033 4.14401 9.92589 4.2492 10.2503 4.25H16.75V5.75H4.54094C4.2261 5.74998 3.91924 5.84902 3.66382 6.03309C3.4084 6.21717 3.21738 6.47695 3.11781 6.77563L1.75 10.8781V2H6.75062ZM17.2506 14.75H2.04062L4.54094 7.25H19.75L17.2506 14.75Z"
                    fill="black"
                  />
                </svg>
                <div class="flex flex-col">
                  <div>{{ data.title }}</div>
                </div>
              </a>
            </td>
            <td
              class="px-4 py-2 2xl:px-6 2xl:py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
            >
              <app-custom-dropdown [hasRawContent]="true">
                <button rawButton>
                  <img
                    src="/assets/imgs/icons/solar-menu-dots.svg"
                    alt="actions"
                  />
                </button>
                <ng-container>
                  <div
                    class="flex flex-col border border-gray3 rounded-lg bg-white min-w-[175px]"
                  >
                    <button
                      [title]="t('Edit')"
                      (click)="openFolderEditModal(data.id)"
                      class="font-semibold text-primary text-xs transition-all duration-200 hover:bg-gray-100 flex gap-2 w-full py-3 border-b px-4"
                    >
                      <img src="/assets/imgs/icons/heroicons-outline/pencil-square.svg" alt=""/>
                      {{ t("Edit") }}
                    </button>
                    <button
                      class="font-semibold text-primary text-xs transition-all duration-200 hover:bg-gray-100 flex gap-2 w-full py-3 border-b px-4"
                      [title]="t('Delete')"
                      (click)="initFolderDelete(data)"
                    >
                      <img src="/assets/imgs/icons/heroicons-outline/trash.svg" alt=""/>
                      {{ t("Delete") }}
                    </button>
                  </div>
                </ng-container>
              </app-custom-dropdown>
            </td>
          </tr>
        </ng-container>
        <ng-container *ngFor="let data of requests">
          <tr
            class="text-sm text-gray4 font-medium border-b hover:bg-gray18 border-white"
            data-expanded="false"
            id="{{ data.id }}"
          >
            <td
              class="px-3 py-4 font-medium text-light-blue3 text-13px whitespace-nowrap truncate max-w-[100px]"
            >
              {{ data.project_number }}
            </td>
            <td
              class="px-4 py-2 2xl:px-6 2xl:py-4 font-medium text-base text-gray4 whitespace-nowrap max-w-[300px]"
            >
              <a
                [routerLink]="data.shared_with_client ?  '/request/' + data.public_hash : '/requests/edit/' + data.id"
                class="flex items-center gap-2"
              >
                <div class="h-6 w-6">
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M3.17598 18.8243C4.51982 20.1663 6.67948 20.1663 11.0007 20.1663C15.3218 20.1663 17.4824 20.1663 18.8244 18.8234C20.1673 17.4823 20.1673 15.3208 20.1673 10.9997C20.1673 6.67851 20.1673 4.51792 18.8244 3.17501C17.4833 1.83301 15.3218 1.83301 11.0007 1.83301C6.67948 1.83301 4.5189 1.83301 3.17598 3.17501C1.83398 4.51884 1.83398 6.67851 1.83398 10.9997C1.83398 15.3208 1.83398 17.4823 3.17598 18.8243ZM6.93523 11.9787C6.81526 11.8413 6.64563 11.7572 6.46366 11.7449C6.2817 11.7327 6.1023 11.7932 5.96494 11.9131C5.82758 12.0331 5.74351 12.2027 5.73122 12.3847C5.71893 12.5667 5.77942 12.7461 5.8994 12.8834L8.6494 16.034C8.74137 16.1394 8.86328 16.2142 8.99886 16.2484C9.13445 16.2827 9.27725 16.2748 9.40823 16.2258C9.53921 16.1768 9.65213 16.089 9.73192 15.9742C9.81172 15.8593 9.85459 15.7229 9.85482 15.583V6.41634C9.85482 6.234 9.78238 6.05914 9.65345 5.93021C9.52452 5.80127 9.34965 5.72884 9.16732 5.72884C8.98498 5.72884 8.81011 5.80127 8.68118 5.93021C8.55225 6.05914 8.47982 6.234 8.47982 6.41634V13.7497L6.93523 11.9787ZM12.5929 5.77192C12.4617 5.82105 12.3486 5.9091 12.2688 6.02427C12.1891 6.13944 12.1464 6.27624 12.1465 6.41634V15.583C12.1465 15.7653 12.2189 15.9402 12.3478 16.0691C12.4768 16.1981 12.6516 16.2705 12.834 16.2705C13.0163 16.2705 13.1912 16.1981 13.3201 16.0691C13.4491 15.9402 13.5215 15.7653 13.5215 15.583V8.24967L15.0661 10.0188C15.1871 10.152 15.3555 10.2325 15.5351 10.2429C15.7148 10.2533 15.8913 10.1929 16.0269 10.0746C16.1625 9.95625 16.2463 9.78949 16.2603 9.61009C16.2744 9.43069 16.2175 9.25294 16.1019 9.11501L13.3519 5.96442C13.26 5.85892 13.1381 5.78396 13.0025 5.74956C12.8669 5.71516 12.724 5.72297 12.5929 5.77192Z"
                      fill="#426C7D"
                    />
                  </svg>
                </div>
                <span class="truncate font-bold">
                  {{ data.title }}
                </span>
              </a>
            </td>
            <td
              class="px-4 py-2 2xl:px-6 2xl:py-4 text-sm font-semibold text-gray4 whitespace-nowrap truncate max-w-[200px]"
            >
              {{ data.client.company_name }}
            </td>
            <td
              class="pr-3 py-4 font-medium text-xs text-primary whitespace-nowrap"
            >
              <app-status-display [statusId]="data.metadata?.overall_status_id || data.status.id"></app-status-display>
            </td>
            <td class="pr-3 py-4 font-semibold text-gray4 whitespace-nowrap">
              {{ data.due_date | date: "dd/MM/yy" }}
            </td>
            <td class="pr-3 py-4 font-semibold text-gray4 whitespace-nowrap">
              {{ data.updated_at | date: "dd/MM/yy" }}
            </td>
            <td class="pr-3 py-4 font-semibold text-gray4 whitespace-nowrap">
              {{ data.created_at | date: "dd/MM/yy" }}
            </td>
            <td class="py-4 font-medium text-gray4 whitespace-nowrap">
              <app-request-list-file-preview
                [request]="data"
              ></app-request-list-file-preview>
            </td>
            <td
              class="px-4 py-2 2xl:px-6 2xl:py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
            >
              <app-custom-dropdown [hasRawContent]="true">
                <button rawButton>
                  <img
                    src="/assets/imgs/icons/solar-menu-dots.svg"
                    alt="actions"
                  />
                </button>
                <ng-container>
                  <div
                    class="flex flex-col border border-gray3 rounded-lg bg-white min-w-[175px]"
                  >
                    <button
                      class="font-semibold text-primary text-xs transition-all duration-200 hover:bg-gray-100 flex gap-2 w-full py-3 border-b px-4"
                      [title]="t('Share')"
                      (click)="openSendModal(data)"
                    >
                      <img src="/assets/imgs/icons/plain.svg" alt=""/>
                      {{ t("Share") }}
                    </button>
                    <button
                      class="font-semibold text-primary text-xs transition-all duration-200 hover:bg-gray-100 flex gap-2 w-full py-3 border-b px-4"
                      [title]="t('Copy link')"
                      (click)="copyPublicLinkToClipboard(data.public_hash)"
                    >
                      <img src="/assets/imgs/icons/link.svg" alt=""/>
                      {{ t("Copy Link") }}
                    </button>
                    <a
                      class="font-semibold text-primary text-xs transition-all duration-200 hover:bg-gray-100 flex gap-2 w-full py-3 border-b px-4"
                      title="Duplicate request"
                      routerLink="/requests/new"
                      [queryParams]="{ duplicate_with: data.id }"
                    >
                      <img src="/assets/imgs/icons/copy.svg" alt=""/>
                      {{ t("Duplicate") }}
                    </a>
                    <a
                      [title]="t('View')"
                      [routerLink]="'/request/' + data.public_hash"
                      target="_blank"
                      class="font-semibold text-primary text-xs transition-all duration-200 hover:bg-gray-100 flex gap-2 w-full py-3 border-b px-4"
                    >
                      <img src="/assets/imgs/icons/maximize_square.svg" alt=""/>
                      {{ t("View") }}
                    </a>
                    <a
                      [title]="t('Edit')"
                      [routerLink]="'/requests/edit/' + data.id"
                      class="font-semibold text-primary text-xs transition-all duration-200 hover:bg-gray-100 flex gap-2 w-full py-3 border-b px-4"
                    >
                      <img src="/assets/imgs/icons/heroicons-outline/pencil-square.svg" alt=""/>
                      {{ t("Edit") }}
                    </a>
                    <button
                      class="font-semibold text-primary text-xs transition-all duration-200 hover:bg-gray-100 flex gap-2 w-full py-3 border-b px-4"
                      [title]="t('Delete')"
                      (click)="initDeleteRequest(data)"
                    >
                      <img src="/assets/imgs/icons/heroicons-outline/trash.svg" alt=""/>
                      {{ t("Delete") }}
                    </button>
                  </div>
                </ng-container>
              </app-custom-dropdown>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
  <app-pagination [total]="totalRequestsCount"></app-pagination>
</div>

<app-custom-modal [modalEvents]="deleteRequestModalEvents" classes="max-w-lg">
  <h1 header class="text-xl font-semibold" transloco="Delete Confirmation">
    Delete Confirmation
  </h1>
  <div content-body class="w-full flex flex-col items-center justify-center py-6 text-center" *transloco="let t">
      <svg
        class="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 20 20"
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
        />
      </svg>
      <h3
        class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400"
        transloco="Are you sure you want to remove this request?"
      >
        Are you sure you want to remove this request?
      </h3>
  </div>
  <div footer class="w-full justify-between flex">
    <button
      type="button"
      class="tw-btn-ghost bg-white min-w-[130px]"
      transloco="No"
      (click)="closeDeleteModal()"
      [disabled]="isDeleting"
    >
      No
    </button>
    <button
      type="button"
      class="tw-btn-ghost bg-semantic-danger-500 text-white min-w-[130px]"
      transloco="Yes"
      [disabled]="isDeleting"
      (click)="handleRequestDelete()"
    >
      Yes
    </button>
  </div>
</app-custom-modal>

<app-custom-modal
  [modalEvents]="folderDeleteConfirmModalEvents"
  classes="max-w-lg"
>
  <h1 header class="text-xl font-semibold" transloco="Delete Confirmation">
    Delete Confirmation
  </h1>
  <div content-body class="p-4 md:p-5 text-center">
    <svg
      class="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
      />
    </svg>
    <h3
      class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400"
      transloco="This action will delete all subfolders & requests inside. Are you sure you want to remove this Folder?"
    >
      This action will delete all subfolders & requests inside. Are you sure you
      want to remove this Folder?
    </h3>
  </div>
  <div footer class="flex justify-between w-full">
    <button
      (click)="closeDeleteConfirmModal()"
      type="button"
      class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-xs 2xl:text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
      transloco="No"
    >
      No
    </button>
    <button
      type="button"
      (click)="handleFolderDelete()"
      class="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-xs 2xl:text-sm inline-flex items-center px-5 py-2.5 text-center me-2"
      transloco="Yes"
    >
      Yes
    </button>
  </div>
</app-custom-modal>
