import {
  Component,
  Input,
} from '@angular/core';
import {TranslocoService} from "@ngneat/transloco";

@Component({
  selector: 'app-status-display',
  templateUrl: './status-display.component.html',
  styleUrls: ['./status-display.component.scss'],
})
export class StatusDisplayComponent {
  @Input() statusId!: number;
  @Input() variant: number = 1;

  statusMap = new Map([
    [1, { text: `Warten auf Upload`, icon: 'hour-glass.svg', color: 'gray7' }],
    [10, { text: `New`, icon: 'new_request_status.svg', color: 'blue4' }],
    [101, { text: `Daten hochgeladen`,  icon: 'pending-submit.svg', color: 'orange1' }],
    [102, { text: `Daten Fehler`, icon: 'bs-exclamation.svg', color: 'red3' }],
    [104, { text: `Abgelehnt d. Kunde`, icon: 'bs-exclamation.svg', color: 'red3' }],
    [106, { text: `Proof uploaded`, icon: 'bs-file-earmark-break.svg', color: 'purple1' }],
    [2, { text: `Warten auf Agentur`, icon: 'hour-glass.svg', color: 'light-blue2' }],
    [202, { text: `Abgelehnt d. Agentur`, icon: 'bs-exclamation.svg', color: 'red3' }],
    [203, { text: `Proof uploaded`, icon: 'bs-file-earmark-break.svg', color: 'purple1' }],
    [204, { text: `Abgelehnt d. Kunde`, icon: 'bs-exclamation.svg', color: 'red3' }],
    [205, { text: `Freigabe`, icon: 'bs-path-check.svg', color: 'green3' }],
    [206, { text: `Proof approved`, icon: 'bs-path-check.svg', color: 'purple2' }],
    [207, { text: `Proof declined`, icon: 'bs-exclamation.svg', color: 'red3' }],
    [3, { text: `Warten auf Produzent`, icon: 'hour-glass.svg', color: 'light-blue4' }],
    [302, { text: `Abgelehnt d. Produzent`, icon: 'bs-exclamation.svg', color: 'red3' }],
    [303, { text: `Proof uploaded`, icon: 'bs-file-earmark-break.svg', color: 'purple1' }],
    [304, { text: `Abgelehnt d. Kunde`, icon: 'bs-exclamation.svg', color: 'red3' }],
    [305, { text: `Freigabe`, icon: 'bs-path-check.svg', color: 'green3' }],
    [4, { text: `Freigabe Agentur`, icon: 'bs-path-check.svg', color: 'green2' }],
    [401, { text: `Freigabe Produzent`, icon: 'bs-path-check.svg', color: 'green4' }],
    [9, { text: `Request completed`, icon: 'bs-path-check.svg', color: 'primary' }],
  ]);

  constructor(private translocoService: TranslocoService) {}

  get statusText() {
    return this.translocoService.translate(this.statusMap.get(this.statusId || 1)!.text);
  }

  get statusIcon() {
    return this.statusMap.get(this.statusId || 1)!.icon;
  }

  get bgColor() {
    return `bg-${this.statusMap.get(this.statusId || 1)!.color}`;
  }

  get textColor() {
    return `text-${this.statusMap.get(this.statusId || 1)!.color}`;
  }
}
