<ng-container *transloco="let t">
  <section class="mb-16 font-Satoshi" *ngIf="currentUserLicense">
    <h2 class="text-2xl font-semibold font-Montserrat" transloco="Your Package">
      Your Package
    </h2>
    <div
      class="my-4 border p-8 rounded-xl hover:bg-gray-50 transition-all duration-300 w-fit"
    >
      <div class="font-bold text-xl mb-6">
        {{ currentUserLicense!.package?.name }}
      </div>
      <div>
        {{ t("Users:") }}
        <span class="font-bold">{{
          currentUserLicense!.usage?.userCount
        }}</span>
        {{ t("of") }}
        {{ currentUserLicense!.usage.availableUserCount }}
      </div>
      <div>
        {{ t("Projects:") }}
        <span class="font-bold">{{
          currentUserLicense!.usage.projectCount
        }}</span>
        {{ t("of") }}
        {{ currentUserLicense!.usage.availableProjectCount }}
      </div>
      <div>
        {{ t("Storage:") }}
        <span class="font-bold">{{
          currentUserLicense.usage.storageUsage | fileSize: 1
        }}</span>
        /
        {{ currentUserLicense.usage.availableStorage | fileSize }}
      </div>
    </div>
  </section>
  <section class="mb-16 font-Satoshi" *hasCapability="'EDIT_CUSTOMER'">
    <h2
      class="text-2xl font-semibold font-Montserrat"
      transloco="Change Package"
    >
      Change Package
    </h2>
    <div class="w-full flex justify-center my-4">
      <div class="flex">
        <button
          class="rounded-r-none w-28"
          [ngClass]="{
            'tw-btn-blue': packageSubscriptionType === 'month',
            'tw-btn-ghost bg-white': packageSubscriptionType !== 'month'
          }"
          (click)="packageSubscriptionType = 'month'"
          transloco="Monthly"
        >
          Monthly
        </button>
        <button
          class="rounded-l-none w-28"
          [ngClass]="{
            'tw-btn-blue': packageSubscriptionType === 'year',
            'tw-btn-ghost bg-white': packageSubscriptionType !== 'year'
          }"
          (click)="packageSubscriptionType = 'year'"
          transloco="Yearly"
        >
          Yearly
        </button>
      </div>
    </div>
    <div class="flex gap-10 flex-wrap">
      <div
        class="my-4 rounded-2xl w-[300px] bg-white hover:bg-gray-50 transition-all duration-300"
        *ngFor="let p of availablePackages"
      >
        <div>
          <div
            class="bg-light-blue2 rounded-t-2xl min-h-[110px] relative flex items-end"
          >
            <div *ngIf="p.tagline" class="px-8 absolute top-0 left-0">
              <div
                class="w-fit px-2 py-1 bg-green7 text-white uppercase text-xs font-bold"
              >
                {{ t(p.tagline) }}
              </div>
            </div>
            <div class="px-8 pt-7 pb-4 font-bold text-22px text-white">
              {{ p.name }}
            </div>
          </div>
          <div class="p-8">
            <div class="flex gap-2 items-center">
              <ng-container *ngIf="packageSubscriptionType === 'month'">
                <div class="font-bold text-3xl whitespace-nowrap">
                  € {{ p.monthly_price | nativeNumber: "1.2-2":true }}
                </div>
                <div class="text-xs text-primary font-medium">
                  {{ t("per month") }}
                </div>
              </ng-container>
              <ng-container *ngIf="packageSubscriptionType === 'year'">
                <div class="font-bold text-3xl whitespace-nowrap">
                  € {{ p.yearly_price | nativeNumber: "1.2-2":true }}
                </div>
                <div class="text-xs text-primary font-medium">
                  {{ t("per year") }}
                </div>
              </ng-container>
            </div>
            <button
              class="tw-btn-secondary w-full mt-6 mb-4"
              (click)="initPackageSubscription(p.id)"
              transloco="Subscribe"
              [disabled]="subscriptionButtonClicked"
            >
              Subscribe
            </button>
            <div>
              <div class="font-medium mb-2" transloco="This includes:">
                This includes:
              </div>
              <ul class="font-medium text-sm">
                <li class="my-1">
                  <span class="text-light-blue2 mr-1">
                    <mat-icon class="check-icon">check_circle</mat-icon>
                  </span>
                  {{ p.included_storage_space | fileSize }}
                  {{ t("Speicherplatz") }}
                </li>
                <li class="my-1">
                  <span class="text-light-blue2 mr-1">
                    <mat-icon class="check-icon">check_circle</mat-icon>
                  </span>
                  {{ p.max_user_count }} {{ t("User") }}
                </li>
                <li class="my-1">
                  <span class="text-light-blue2 mr-1">
                    <mat-icon class="check-icon">check_circle</mat-icon>
                  </span>
                  {{ p.max_user_count }} {{ t("Aktiv Projekte") }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="mb-16 font-Satoshi" *hasCapability="'EDIT_CUSTOMER'">
    <h2 class="text-2xl font-semibold font-Montserrat" transloco="Addons">
      Addons
    </h2>
    <div class="w-full flex justify-center my-4">
      <div class="flex">
        <button
          class="rounded-r-none w-28"
          [ngClass]="{
            'tw-btn-blue': storageSubscriptionType === 'month',
            'tw-btn-ghost bg-white': storageSubscriptionType !== 'month'
          }"
          (click)="storageSubscriptionType = 'month'"
          transloco="Monthly"
        >
          Monthly
        </button>
        <button
          class="rounded-l-none w-28"
          [ngClass]="{
            'tw-btn-blue': storageSubscriptionType === 'year',
            'tw-btn-ghost bg-white': storageSubscriptionType !== 'year'
          }"
          (click)="storageSubscriptionType = 'year'"
          transloco="Yearly"
        >
          Yearly
        </button>
      </div>
    </div>
    <div class="flex gap-4 flex-wrap">
      <div
        class="my-4 rounded-2xl w-[300px] bg-white hover:bg-gray-50 transition-all duration-300"
        *ngFor="let p of availableStorageAddons"
      >
        <div>
          <div
            class="bg-light-blue2 rounded-t-2xl min-h-[110px] relative flex items-end"
          >
            <div *ngIf="p.tagline" class="px-8 absolute top-0 left-0">
              <div
                class="w-fit px-2 py-1 bg-green7 text-white uppercase text-xs font-bold"
              >
                {{ t(p.tagline) }}
              </div>
            </div>
            <div class="px-8 pt-7 pb-4 font-bold text-22px text-white">
              {{ p.name }}
            </div>
          </div>
          <div class="p-8">
            <div class="flex gap-2 items-center">
              <ng-container *ngIf="storageSubscriptionType === 'month'">
                <div class="font-bold text-3xl whitespace-nowrap">
                  € {{ p.monthly_price | nativeNumber: "1.2-2":true }}
                </div>
                <div class="text-xs text-primary font-medium">
                  {{ t("per month") }}
                </div>
              </ng-container>
              <ng-container *ngIf="storageSubscriptionType === 'year'">
                <div class="font-bold text-3xl whitespace-nowrap">
                  € {{ p.yearly_price | nativeNumber: "1.2-2":true }}
                </div>
                <div class="text-xs text-primary font-medium">
                  {{ t("per year") }}
                </div>
              </ng-container>
            </div>
            <button
              class="tw-btn-secondary w-full mt-6 mb-4"
              (click)="initStorageSubscription(p.id)"
              transloco="Subscribe"
              [disabled]="subscriptionButtonClicked"
            >
              Subscribe
            </button>
            <div>
              <div class="font-medium mb-2" transloco="This includes:">
                This includes:
              </div>
              <ul class="font-medium text-sm">
                <li class="my-1">
                  <span class="text-light-blue2 mr-1">
                    <mat-icon class="check-icon">check_circle</mat-icon>
                  </span>
                  {{ p.included_storage_space | fileSize }}
                  {{ t("Speicherplatz") }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex gap-4 flex-wrap">
      <div
        class="my-4 rounded-2xl w-[300px] bg-white hover:bg-gray-50 transition-all duration-300"
        *ngFor="let p of availableUserAddons"
      >
        <div>
          <div
            class="bg-light-blue2 rounded-t-2xl min-h-[110px] relative flex items-end"
          >
            <div *ngIf="p.tagline" class="px-8 absolute top-0 left-0">
              <div
                class="w-fit px-2 py-1 bg-green7 text-white uppercase text-xs font-bold"
              >
                {{ t(p.tagline) }}
              </div>
            </div>
            <div class="px-8 pt-7 pb-4 font-bold text-22px text-white">
              {{ p.name }}
            </div>
          </div>
          <div class="p-8">
            <div class="flex gap-2 items-center">
              <div class="font-bold text-3xl whitespace-nowrap">
                € {{ p.monthly_price | nativeNumber: "1.2-2":true }}
              </div>
              <div class="text-xs text-primary font-medium">
                {{ t("per month") }}
              </div>
            </div>
            <button
              class="tw-btn-secondary w-full mt-6 mb-4"
              (click)="initUserSubscription(p.id)"
              transloco="Subscribe"
              [disabled]="subscriptionButtonClicked"
            >
              Subscribe
            </button>
            <div>
              <div class="font-medium mb-2" transloco="This includes:">
                This includes:
              </div>
              <ul class="font-medium text-sm">
                <li class="my-1">
                  <span class="text-light-blue2 mr-1">
                    <mat-icon class="check-icon">check_circle</mat-icon>
                  </span>
                  {{ p.included_user_count }} {{ t("User") }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <p
    class="text-center text-primary text-15px font-medium mb-8"
    transloco="All prices excl. VAT"
  >
    All prices excl. VAT
  </p>
</ng-container>
