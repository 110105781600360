<div
  [formGroup]="editRequestModalService.requestFormGroup"
  class="text-primary px-6 2xl:px-0 font-Satoshi pb-20"
  *transloco="let t"
>
  <section
    class="flex justify-between flex-wrap gap-4 border-b border-gray5 pt-3 pb-4"
  >
    <div class="flex flex-wrap gap-6">
      <button
        class="h-[60px] 2xl:h-[72px] w-[60px] 2xl:w-[72px] flex justify-center items-center rounded-lg border border-gray3"
        (click)="goBack()"
        [disabled]="editRequestModalService.requestFormGroup.disabled"
      >
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.8214 21.1789C13.509 20.8664 13.3334 20.4426 13.3334 20.0006C13.3334 19.5587 13.509 19.1348 13.8214 18.8223L23.2498 9.39395C23.4035 9.23476 23.5874 9.10779 23.7907 9.02044C23.9941 8.93309 24.2128 8.88712 24.4341 8.88519C24.6554 8.88327 24.8748 8.92544 25.0797 9.00924C25.2845 9.09304 25.4706 9.2168 25.6271 9.37329C25.7836 9.52978 25.9073 9.71586 25.9911 9.92069C26.0749 10.1255 26.1171 10.345 26.1152 10.5663C26.1132 10.7876 26.0673 11.0063 25.9799 11.2096C25.8926 11.413 25.7656 11.5969 25.6064 11.7506L17.3564 20.0006L25.6064 28.2506C25.91 28.565 26.078 28.986 26.0742 29.4229C26.0704 29.8599 25.8951 30.278 25.5861 30.587C25.2771 30.896 24.8591 31.0713 24.4221 31.0751C23.9851 31.0789 23.5641 30.9109 23.2498 30.6073L13.8214 21.1789Z"
            fill="black"
          />
        </svg>
      </button>
      <div class="flex flex-col gap-2">
        <h1 class="font-bold text-2xl font-Montserrat">
          <span *ngIf="isEditPage" transloco="Edit Request">Edit Request </span>
          <span *ngIf="!isEditPage" transloco="Create New Request"
            >Create New Request
          </span>
        </h1>
        <ul class="flex items-center gap-2 text-xs 2xl:text-sm">
          <li class="font-normal text-xs text-gray4">
            <a routerLink="/requests" transloco="Requests">Requests</a>
            <span> / </span>
          </li>
          <li
            class="font-normal text-xs text-gray4"
            *ngIf="fromRequestFolderId"
          >
            <a routerLink="/requests/folders/{{ fromRequestFolderId }}"
              >{{ fromRequestFolderData?.title }} /</a
            >
          </li>
          <li class="font-semibold text-13px text-primary">
            {{ isEditPage ? t("Edit Request") : t("Create New Request") }}
          </li>
        </ul>
      </div>
    </div>
    <div class="flex flex-wrap gap-4 items-center">
      <button
        class="tw-btn-ghost bg-white min-w-[100px]"
        type="button"
        [disabled]="editRequestModalService.requestFormGroup.disabled"
        (click)="goBack()"
        transloco="Cancel"
      >
        Cancel
      </button>
      <button
        class="tw-btn-blue min-w-[100px]"
        type="button"
        [disabled]="editRequestModalService.requestFormGroup.disabled"
        (click)="editRequestModalService.handleSubmit()"
        transloco="Save"
      >
        Save
      </button>
      <button
        class="tw-btn-secondary min-w-[100px]"
        type="button"
        [disabled]="editRequestModalService.requestFormGroup.disabled"
        (click)="shareRequest()"
        transloco="Submit"
        *ngIf="isEditPage"
      >
        Submit
      </button>
    </div>
  </section>
  <section class="flex-col pt-4" *transloco="let t">
    <h2
      class="mt-10 font-Montserrat mb-6 font-bold text-xl"
      transloco="General Info"
    >
      General Info
    </h2>
    <div class="grid grid-cols-1 lg:grid-cols-3 gap-6">
      <div class="bg-gray16 px-6 py-5">
        <div class="flex flex-col gap-2">
          <label class="font-bold text-15px" transloco="Folder">Folder</label>
          <div class="bg-white rounded-lg">
            <app-custom-dropdown
              [buttonText]="
                this.editRequestModalService.requestFolderControl.value
                  ? requestFolderService.localFolderNameMap.get(
                      this.editRequestModalService.requestFolderControl.value
                    )
                  : t('Select Folder')
              "
            >
              <ng-template #recursiveList let-list>
                <li *ngFor="let requestFolder of list" class="border-l">
                  <button
                    class="flex gap-2 my-1 py-1 px-2 cursor-pointer hover:bg-gray-200 text-primary font-medium duration-200 w-full"
                    (click)="changeRequestFolderValue(requestFolder.id)"
                  >
                    {{ requestFolder.title }}
                  </button>
                  <ul *ngIf="requestFolder.children?.length" class="ml-3">
                    <ng-container
                      *ngTemplateOutlet="
                        recursiveList;
                        context: { $implicit: requestFolder.children }
                      "
                    ></ng-container>
                  </ul>
                </li>
              </ng-template>
              <ng-container
                *ngTemplateOutlet="
                  recursiveList;
                  context: { $implicit: requestFolderList }
                "
              ></ng-container>
            </app-custom-dropdown>
          </div>
        </div>
        <div class="flex flex-col gap-2 mt-7">
          <label class="font-bold text-sm" for="client_id" transloco="Client"
            >Client</label
          >
          <div class="rounded-lg bg-white">
            <app-custom-dropdown
              id="client_id"
              [buttonText]="
                this.editRequestModalService.clientIdControl.value
                  ? clientService.localClientNameMap.get(
                      this.editRequestModalService.clientIdControl.value
                    )
                  : selectClientText
              "
              [hasError]="
                editRequestModalService.requestFormGroup.controls.client_id
                  .invalid &&
                editRequestModalService.requestFormGroup.controls.client_id
                  .touched
              "
              *ngIf="!editRequestModalService.requestFormGroup.value.id"
            >
              <li header>
                <div class="relative min-w-[380px] flex-grow my-3 mx-2">
                  <input
                    type="search"
                    [formControl]="clientFilterText"
                    class="block w-full p-3 2xl:p-4 text-xs 2xl:text-sm text-gray-900 border border-gray-300 rounded-lg focus:ring-darkBlue1 focus:border-darkBlue1"
                    [placeholder]="t('Search clients...')"
                  />
                </div>
              </li>
              <li *ngFor="let client of filteredAvailableClients" class="">
                <button
                  class="flex gap-2 my-1 py-1 px-2 cursor-pointer hover:bg-gray-200 text-primary font-medium duration-200 w-full"
                  (click)="
                    editRequestModalService.clientIdControl.setValue(client.id)
                  "
                >
                  {{ client.company_name }}
                </button>
              </li>
            </app-custom-dropdown>
          </div>
          <div
            class="text-red-400"
            *ngIf="
              editRequestModalService.requestFormGroup.controls.client_id
                .errors &&
              editRequestModalService.requestFormGroup.controls.client_id
                .touched
            "
          >
            <small
              *ngIf="
                editRequestModalService.requestFormGroup.controls.client_id
                  .errors.required
              "
              transloco="required"
              >required</small
            >
          </div>
          <div
            *ngIf="editRequestModalService.requestFormGroup.value.id"
            class="bg-white border border-gray3 rounded-lg focus:border-gray15 focus:ring-1 focus:ring-gray15 focus:outline-none p-3 text-sm w-full font-Satoshi text-15px font-medium text-primary"
          >
            {{
              clientService.getLocalClientById(
                editRequestModalService.requestFormGroup.value.client_id
              )?.company_name
            }}
          </div>
        </div>
        <div class="flex flex-col gap-2 mt-7">
          <label
            class="font-bold text-sm"
            for="contact_person_id"
            transloco="Contact Person"
            >Contact Person</label
          >
          <div class="rounded-lg bg-white">
            <app-custom-dropdown
              id="contact_person_id"
              [buttonText]="selectedContactText"
              [hasError]="
                editRequestModalService.requestFormGroup.controls.contact_id
                  .invalid &&
                editRequestModalService.requestFormGroup.controls.contact_id
                  .touched
              "
            >
              <li
                *ngFor="
                  let contact of clientService.getLocalClientById(
                    editRequestModalService.requestFormGroup.controls.client_id
                      .value
                  )?.contacts || []
                "
                class=""
              >
                <button
                  class="flex gap-2 my-1 py-1 px-2 cursor-pointer hover:bg-gray-200 text-primary font-medium duration-200 w-full"
                  (click)="
                    editRequestModalService.contactIdControl.setValue(
                      contact.id
                    )
                  "
                >
                  {{ contact.first_name }} {{ contact.last_name }} -
                  {{ contact.email }}
                </button>
              </li>
            </app-custom-dropdown>
          </div>
          <div
            class="text-red-400"
            *ngIf="
              editRequestModalService.requestFormGroup.controls.contact_id
                .errors &&
              editRequestModalService.requestFormGroup.controls.contact_id
                .touched
            "
          >
            <small
              *ngIf="
                editRequestModalService.requestFormGroup.controls.contact_id
                  .errors.required
              "
              transloco="required"
              >required</small
            >
          </div>
        </div>
      </div>
      <div class="lg:col-span-2 bg-white rounded-lg py-5 px-6">
        <div class="flex flex-col gap-2">
          <app-form-input
            [label]="t('Request Title*')"
            [placeholder]="t('Input request title') + '...'"
            [fc]="editRequestModalService.requestFormGroup.controls.title"
            [hasError]="
              editRequestModalService.requestFormGroup.controls.title.invalid &&
              editRequestModalService.requestFormGroup.controls.title.touched
            "
            [errorMessage]="
              editRequestModalService.requestFormGroup.controls.title.errors
                ?.maxlength
                ? t('Must be less than 255 characters')
                : t('required')
            "
          ></app-form-input>
        </div>
        <div class="flex flex-col gap-2 mt-7">
          <label
            class="font-semibold text-sm"
            for="description"
            transloco="Description"
            >Description</label
          >
          <textarea
            id="description"
            rows="1"
            class="border border-gray3 rounded-lg focus:border-gray15 focus:ring-1 focus:ring-gray15 focus:outline-none p-3 text-sm w-full font-Satoshi text-15px font-medium text-primary"
            [placeholder]="t('Enter your description...')"
            formControlName="description"
            [class.border-red-400]="
              editRequestModalService.requestFormGroup.controls.description
                .invalid &&
              editRequestModalService.requestFormGroup.controls.description
                .touched
            "
          ></textarea>
          <div
            class="text-red-400"
            *ngIf="
              editRequestModalService.requestFormGroup.controls.description
                .errors &&
              editRequestModalService.requestFormGroup.controls.description
                .touched
            "
          >
            <small
              *ngIf="
                editRequestModalService.requestFormGroup.controls.description
                  .errors.required
              "
              transloco="required"
              >required</small
            >
            <small
              *ngIf="
                editRequestModalService.requestFormGroup.controls.description
                  .errors.maxlength
              "
              transloco="Must be less than 1000 characters"
            >
              Must be less than 1000 characters
            </small>
          </div>
        </div>
        <div class="grid grid-cols-1 lg:grid-cols-3 mt-7 gap-x-16 gap-y-4">
          <div class="flex flex-col gap-2">
            <div class="flex gap-3">
              <label
                class="font-bold text-sm flex"
                for="project_number"
                transloco="Project Number (internal)"
                >Project Number (internal)</label
              >
            </div>
            <input
              type="text"
              formControlName="project_number"
              id="project_number"
              [placeholder]="t('Input project number...')"
              class="border border-gray3 rounded-lg focus:border-gray15 focus:ring-1 focus:ring-gray15 focus:outline-none p-3 text-sm w-full font-Satoshi text-15px font-medium text-primary"
              [class.border-red-400]="
                editRequestModalService.requestFormGroup.controls.project_number
                  .invalid &&
                editRequestModalService.requestFormGroup.controls.project_number
                  .touched
              "
            />
            <div
              class="text-red-400"
              *ngIf="
                editRequestModalService.requestFormGroup.controls.project_number
                  .errors &&
                editRequestModalService.requestFormGroup.controls.project_number
                  .touched
              "
            >
              <small
                *ngIf="
                  editRequestModalService.requestFormGroup.controls
                    .project_number.errors.maxlength
                "
                transloco="Must be less than 255 characters"
              >
                Must be less than 255 characters
              </small>
            </div>
          </div>
          <div class="flex flex-col gap-2">
            <div class="flex gap-3">
              <label
                class="font-bold text-sm flex"
                for="project_number"
                transloco="Projektnummer (Producer)"
                >Projektnummer (Producer)
              </label>
            </div>
            <input
              type="text"
              formControlName="producer_project_number"
              id="producer_project_number"
              [placeholder]="t('Input project number...')"
              class="border border-gray3 rounded-lg focus:border-gray15 focus:ring-1 focus:ring-gray15 focus:outline-none p-3 text-sm w-full font-Satoshi text-15px font-medium text-primary"
              [class.border-red-400]="
                editRequestModalService.requestFormGroup.controls.producer_project_number
                  .invalid &&
                editRequestModalService.requestFormGroup.controls.producer_project_number
                  .touched
              "
            />
            <div
              class="text-red-400"
              *ngIf="
                editRequestModalService.requestFormGroup.controls.producer_project_number
                  .errors &&
                editRequestModalService.requestFormGroup.controls.producer_project_number
                  .touched
              "
            >
              <small
                *ngIf="
                  editRequestModalService.requestFormGroup.controls
                    .producer_project_number.errors.maxlength
                "
                transloco="Must be less than 255 characters"
              >
                Must be less than 255 characters
              </small>
            </div>
          </div>
          <div class="flex flex-col gap-2">
            <label class="font-bold text-sm flex" for="due_date"
              ><span transloco="Due Date">Due Date</span>*</label
            >
            <input
              type="date"
              formControlName="due_date"
              id="due_date"
              class="border border-gray3 rounded-lg focus:border-gray15 focus:ring-1 focus:ring-gray15 focus:outline-none p-3 text-sm w-full font-Satoshi text-15px font-medium text-primary"
              [class.border-red-400]="
                editRequestModalService.requestFormGroup.controls.due_date
                  .invalid &&
                editRequestModalService.requestFormGroup.controls.due_date
                  .touched
              "
            />
            <div
              class="text-red-400"
              *ngIf="
                editRequestModalService.requestFormGroup.controls.due_date
                  .errors &&
                editRequestModalService.requestFormGroup.controls.due_date
                  .touched
              "
            ></div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="flex-col" *transloco="let t">
    <h2
      class="mt-10 mb-6 font-bold text-xl font-Montserrat"
      transloco="Requirements"
    >
      Requirements
    </h2>
    <div class="grid grid-cols-1 lg:grid-cols-12 gap-4">
      <div class="lg:col-span-4 flex flex-col gap-6">
        <div
          *ngFor="
            let sectionCollection of editRequestModalService
              .sectionCollectionsFormArr.controls;
            let cIndex = index;
            let cIsLast = last
          "
          formArrayName="section_collections"
          class="rounded-xl border border-gray19 p-4 cursor-pointer"
          [ngClass]="{
            'border-2 border-light-blue5 shadow-sm':
              editRequestModalService.selectedCollectionIndex === cIndex
          }"
          (click)="
            editRequestModalService.selectedCollectionIndex = cIndex;
            editRequestModalService.selectedSectionIndex = 0
          "
        >
          <div class="text-xs font-normal text-light-blue3 uppercase">
            {{ t("Collection") }} {{ cIndex + 1 }}
          </div>
          <div
            class="flex w-full justify-between mt-2 pb-1"
            [ngClass]="{
              'border-b mb-2 pb-3':
                editRequestModalService.selectedCollectionIndex === cIndex
            }"
          >
            <div
              class="text-primary text-lg"
              [ngClass]="{
                'font-bold':
                  editRequestModalService.selectedCollectionIndex === cIndex,
                'font-medium':
                  editRequestModalService.selectedCollectionIndex !== cIndex
              }"
            >
              {{
                sectionCollection.get("title")?.value ||
                  t("Unbenanntes Produkt")
              }}
            </div>
            <div class="flex items-start gap-2">
              <button
                (click)="
                  sectionCollectionEditModalService.openModal(
                    cIndex,
                    $any(sectionCollection)
                  )
                "
                class="hover:opacity-80"
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.2411 2.99111L12.3661 1.86612C12.8543 1.37796 13.6457 1.37796 14.1339 1.86612C14.622 2.35427 14.622 3.14573 14.1339 3.63388L7.05479 10.713C6.70234 11.0654 6.26762 11.3245 5.78993 11.4668L4 12L4.53319 10.2101C4.67548 9.73239 4.93456 9.29767 5.28701 8.94522L11.2411 2.99111ZM11.2411 2.99111L13 4.74999M12 9.33333V12.5C12 13.3284 11.3284 14 10.5 14H3.5C2.67157 14 2 13.3284 2 12.5V5.49999C2 4.67157 2.67157 3.99999 3.5 3.99999H6.66667"
                    stroke="#97B7C4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
              <button
                [disabled]="editRequestModalService.requestFormGroup.disabled"
                (click)="
                  editRequestModalService.removeSectionCollection(cIndex)
                "
                class="hover:opacity-80"
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.82692 6L9.59615 12M6.40385 12L6.17308 6M12.8184 3.86038C13.0464 3.89481 13.2736 3.93165 13.5 3.97086M12.8184 3.86038L12.1065 13.115C12.0464 13.8965 11.3948 14.5 10.611 14.5H5.38905C4.60524 14.5 3.95358 13.8965 3.89346 13.115L3.18157 3.86038M12.8184 3.86038C12.0542 3.74496 11.281 3.65657 10.5 3.59622M2.5 3.97086C2.72638 3.93165 2.95358 3.89481 3.18157 3.86038M3.18157 3.86038C3.94585 3.74496 4.719 3.65657 5.5 3.59622M10.5 3.59622V2.98546C10.5 2.19922 9.8929 1.54282 9.10706 1.51768C8.73948 1.50592 8.37043 1.5 8 1.5C7.62957 1.5 7.26052 1.50592 6.89294 1.51768C6.1071 1.54282 5.5 2.19922 5.5 2.98546V3.59622M10.5 3.59622C9.67504 3.53247 8.84131 3.5 8 3.5C7.15869 3.5 6.32496 3.53247 5.5 3.59622"
                    stroke="#97B7C4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
          <ng-container
            *ngIf="editRequestModalService.selectedCollectionIndex === cIndex"
          >
            <app-request-section-list-item
              *ngFor="
                let section of $any(sectionCollection.get('sections'))
                  ?.controls;
                let sectionIndex = index
              "
              [sectionCollection]="$any(sectionCollection)"
              [sectionCollectionIndex]="cIndex"
              [sectionIndex]="sectionIndex"
            ></app-request-section-list-item>
            <button
              transloco="+ Add Section"
              class="w-full text-sm font-bold tw-btn-blue mt-3"
              (click)="
                editRequestModalService.addNewSection($any(sectionCollection))
              "
            >
              + Add Section
            </button>
          </ng-container>
        </div>
        <button
          transloco="+ Add Collection"
          class="tw-btn-secondary mt-2"
          (click)="editRequestModalService.addNewSectionCollection()"
        >
          + Add Collection
        </button>
      </div>
      <div class="lg:col-span-8">
        <app-selected-section-view></app-selected-section-view>
        <div class="flex flex-wrap gap-4 items-center mt-11 justify-end">
          <button
            class="tw-btn-ghost bg-white min-w-[100px]"
            type="button"
            [disabled]="editRequestModalService.requestFormGroup.disabled"
            (click)="goBack()"
            transloco="Cancel"
          >
            Cancel
          </button>
          <button
            class="tw-btn-blue min-w-[100px]"
            type="button"
            [disabled]="editRequestModalService.requestFormGroup.disabled"
            (click)="editRequestModalService.handleSubmit()"
            transloco="Save"
          >
            Save
          </button>
          <button
            class="tw-btn-secondary min-w-[100px]"
            type="button"
            [disabled]="editRequestModalService.requestFormGroup.disabled"
            (click)="shareRequest()"
            transloco="Submit"
            *ngIf="isEditPage"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </section>
</div>
<app-info-upload-modal></app-info-upload-modal>
<app-section-collection-edit-modal></app-section-collection-edit-modal>
