<div>
  <div
    class="w-full flex justify-between gap-4"
    *hasCapability="'MANAGE_USERS'"
  >
    <div>
      <h1
        *ngIf="queryParams.management_type !== 'customers'"
        class="text-2xl font-bold text-primary font-Montserrat"
        transloco="Benutzer"
      >
        Benutzer
      </h1>
      <h1
        *ngIf="queryParams.management_type === 'customers'"
        class="text-2xl font-bold text-primary font-Montserrat"
        transloco="Vertragspartner"
      >
        Vertragspartner
      </h1>
    </div>
    <div>
      <button
        (click)="openNewUserModal()"
        *ngIf="queryParams.management_type !== 'customers'"
        class="tw-btn-secondary py-[10px] px-6"
        transloco="+ New User"
      >
        + New User
      </button>
      <a
        [routerLink]="[ManageSingleCustomerRoute]"
        class="tw-btn-secondary py-[10px] px-6"
        *ngIf="queryParams.management_type === 'customers'"
        transloco="+ Neuer Vertragspartner"
        >+ Neuer Vertragspartner</a
      >
    </div>
  </div>
  <div class="mt-12" *transloco="let t">
    <div class="w-full flex mt-6 flex-wrap gap-4 items-center">
      <div class="relative min-w-[380px] flex-grow">
        <input
          type="search"
          [formControl]="searchControl"
          id="default-search"
          class="block w-full p-3 2xl:p-4 pe-14 2xl:pe-14 text-xs 2xl:text-sm text-gray-900 border border-gray-300 rounded-lg focus:ring-darkBlue1 focus:border-darkBlue1"
          [placeholder]="
            t('Search by name, company name or email of contact person...')
          "
        />
        <button
          (click)="handleSearch()"
          class="absolute right-0.5 top-1/2 -translate-y-1/2 rounded-lg px-4 py-2"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M20.9992 21L16.6992 16.7"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
  <div class="pt-4 font-Satoshi" *transloco="let t">
    <div *ngIf="queryParams.management_type === 'users'">
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table class="w-full mt-4 text-xs 2xl:text-sm text-left text-gray-500">
          <thead class="text-xs 2xl:text-sm text-black font-semibold bg-white">
            <tr>
              <th
                scope="col"
                class="border-l border-t border-b border-gray3 px-6 py-4"
              >
                <app-sortable-table-header
                  [displayName]="t('Firstname')"
                  fieldName="first_name"
                ></app-sortable-table-header>
              </th>
              <th
                scope="col"
                class="border-t border-b border-gray3 px-6 py-4 rounded-l"
              >
                <app-sortable-table-header
                  [displayName]="t('Lastname')"
                  fieldName="last_name"
                ></app-sortable-table-header>
              </th>
              <th
                scope="col"
                class="border-t border-b border-gray3 px-6 py-4 rounded-l"
              >
                <app-sortable-table-header
                  [displayName]="t('E-Mail')"
                  fieldName="email"
                ></app-sortable-table-header>
              </th>
              <th
                scope="col"
                class="border-t border-b border-gray3 px-6 py-4 rounded-l"
              >
                <app-sortable-table-header
                  [displayName]="t('Role')"
                  fieldName="role_id"
                ></app-sortable-table-header>
              </th>
              <th
                scope="col"
                class="border-t border-b border-gray3 px-6 py-4 rounded-l"
                *isSuperAdmin
              >
                <app-sortable-table-header
                  [displayName]="t('Kunde')"
                  fieldName="customer_id"
                ></app-sortable-table-header>
              </th>
              <th
                scope="col"
                class="border-t border-b border-gray3 px-6 py-4 rounded-l"
              >
                <app-sortable-table-header
                  [displayName]="t('Status')"
                  fieldName="enabled"
                ></app-sortable-table-header>
              </th>
              <th
                scope="col"
                class="w-6 border-t border-b border-r border-gray3 pr-6 py-4"
              ></th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let user of allUsers"
              class="font-bold text-base text-gray4 border-b hover:bg-gray18 border-white"
            >
              <td class="px-6 py-4 whitespace-nowrap">
                {{ user.first_name }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap">{{ user.last_name }}</td>
              <td class="px-6 py-4 whitespace-nowrap">{{ user.email }}</td>
              <td class="px-6 py-4 whitespace-nowrap">
                <app-role-name-display
                  [roleId]="user.role_id"
                ></app-role-name-display>
              </td>
              <td class="px-6 py-4 whitespace-nowrap" *isSuperAdmin>
                <app-company-name-display
                  [customerId]="user.customer_id"
                ></app-company-name-display>
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                <div
                  class="border border-orange1 rounded-[100px] px-3 py-2 flex gap-2 w-fit items-center text-primary font-medium text-13px"
                  *ngIf="user.enabled"
                >
                  <svg
                    width="6"
                    height="7"
                    viewBox="0 0 6 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="3" cy="3.5" r="3" fill="#FF8811" />
                  </svg>
                  <span transloco="Active">Active</span>
                </div>
                <div
                  class="border border-gray15 rounded-[100px] px-3 py-2 flex gap-2 w-fit items-center text-gray15 font-medium text-13px"
                  *ngIf="!user.enabled"
                >
                  <svg
                    width="6"
                    height="7"
                    viewBox="0 0 6 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="3" cy="3.5" r="3" fill="#97B7C4" />
                  </svg>
                  <span transloco="Inactive">Inactive</span>
                </div>
              </td>
              <td
                class="px-4 py-2 2xl:px-6 2xl:py-4 font-medium text-gray-900 whitespace-nowrap"
              >
                <div
                  class="flex w-full justify-end"
                  *hasCapability="'MANAGE_USERS'"
                >
                  <div
                    class="flex justify-end border border-gray3 rounded-lg px-4 py-2 w-fit bg-white"
                  >
                    <button
                      class="font-medium text-primary text-xs 2xl:text-sm transition-all duration-200 hover:bg-gray-100"
                      transloco="Edit"
                      (click)="openEditModal(user)"
                    >
                      Edit
                    </button>
                    <div class="text-gray3 mx-2">|</div>
                    <button
                      class="font-medium text-primary text-xs 2xl:text-sm transition-all duration-200 hover:bg-gray-100"
                      transloco="Delete"
                      (click)="openDeleteConfirmModal('user', user.id)"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <app-pagination [total]="totalUsers"></app-pagination>
    </div>
    <div *ngIf="queryParams.management_type === 'customers'">
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table class="w-full mt-4 text-xs 2xl:text-sm text-left text-gray-500">
          <thead class="text-xs 2xl:text-sm text-black font-semibold bg-white">
            <tr>
              <th
                scope="col"
                class="border-t border-b border-gray3 pr-6 py-4 pl-6 w-3/12"
              >
                <app-sortable-table-header
                  class="justify-start"
                  [displayName]="t('Name')"
                  fieldName="label"
                ></app-sortable-table-header>
              </th>
              <th
                scope="col"
                class="border-t border-b border-gray3 px-6 py-4 w-2/12"
              >
                <app-sortable-table-header
                  [displayName]="t('Lizenz')"
                  fieldName="licence"
                ></app-sortable-table-header>
              </th>
              <th
                scope="col"
                class="border-t border-b border-gray3 px-6 py-4 w-2/12"
              >
                <app-sortable-table-header
                  [displayName]="t('Status')"
                  fieldName="enabled"
                ></app-sortable-table-header>
              </th>
              <th
                scope="col"
                class="border-t border-b border-gray3 pr-6 py-4 w-1/12"
              >
                <app-sortable-table-header
                  [displayName]="t('Benutzer')"
                  fieldName="user_count"
                ></app-sortable-table-header>
              </th>
              <th
                scope="col"
                class="border-t border-b border-r border-gray3 pr-6 py-3 w-4/12"
              ></th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="text-sm text-gray4 font-bold border-b hover:bg-gray18 border-white"
              *ngFor="let customer of allCustomers"
            >
              <td class="px-6 py-4 whitespace-nowrap">
                {{ customer.label }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                {{ customer.license?.package?.name }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                <div
                  class="border border-orange1 rounded-[100px] px-3 py-2 flex gap-2 w-fit items-center text-primary font-medium text-13px"
                  *ngIf="customer.enabled"
                >
                  <svg
                    width="6"
                    height="7"
                    viewBox="0 0 6 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="3" cy="3.5" r="3" fill="#FF8811" />
                  </svg>
                  <span transloco="Active">Active</span>
                </div>
                <div
                  class="border border-gray15 rounded-[100px] px-3 py-2 flex gap-2 w-fit items-center text-gray15 font-medium text-13px"
                  *ngIf="!customer.enabled"
                >
                  <svg
                    width="6"
                    height="7"
                    viewBox="0 0 6 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="3" cy="3.5" r="3" fill="#97B7C4" />
                  </svg>
                  <span transloco="Inactive">Inactive</span>
                </div>
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                {{ customer.user_count }}
              </td>
              <td
                class="px-4 py-2 2xl:px-6 2xl:py-4 font-medium text-gray-900 whitespace-nowrap"
              >
                <div class="flex w-full justify-end">
                  <div
                    class="flex justify-end border border-gray3 rounded-lg px-4 py-2 w-fit bg-white"
                  >
                    <button
                      class="font-medium text-primary text-xs 2xl:text-sm transition-all duration-200 hover:bg-gray-100"
                      transloco="Neuer Benutzer"
                      (click)="openNewUserModal(customer.id)"
                    >
                      Neuer Benutzer
                    </button>
                    <div class="text-gray3 mx-2">|</div>
                    <a
                      class="font-medium text-primary text-xs 2xl:text-sm transition-all duration-200 hover:bg-gray-100"
                      transloco="Edit"
                      routerLink="/user-management/customer/{{ customer.id }}"
                    >
                      Edit
                    </a>
                    <div class="text-gray3 mx-2">|</div>
                    <button
                      class="font-medium text-primary text-xs 2xl:text-sm transition-all duration-200 hover:bg-gray-100"
                      transloco="Delete"
                      (click)="openDeleteConfirmModal('customer', customer.id)"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <app-pagination [total]="totalCustomers"></app-pagination>
    </div>
  </div>
</div>
<app-edit-user-modal></app-edit-user-modal>
<app-custom-modal [modalEvents]="deleteConfirmModalEvents" classes="max-w-lg">
  <h1 header class="text-xl font-semibold" transloco="Delete Confirmation">
    Delete Confirmation
  </h1>
  <div
    content-body
    class="w-full flex flex-col items-center justify-center py-6 text-center"
    *transloco="let t"
  >
    <svg
      class="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
      />
    </svg>
    <h3
      *ngIf="pendingDeleteItem?.type === 'customer'"
      transloco="Are you sure you want to remove this customer"
      class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400"
    >
      Are you sure you want to remove this customer
    </h3>
    <h3
      *ngIf="pendingDeleteItem?.type === 'user'"
      transloco="Are you sure you want to remove this user"
      class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400"
    >
      Are you sure you want to remove this user
    </h3>
  </div>
  <div footer class="w-full justify-between flex">
    <button
      type="button"
      class="tw-btn-ghost bg-white min-w-[130px]"
      transloco="No"
      (click)="closeDeleteConfirmModal()"
      [disabled]="isDeleting"
    >
      No
    </button>
    <button
      type="button"
      class="tw-btn-ghost bg-semantic-danger-500 text-white min-w-[130px]"
      transloco="Yes"
      [disabled]="isDeleting"
      (click)="handleDeleteConfirm()"
    >
      Yes
    </button>
  </div>
</app-custom-modal>
