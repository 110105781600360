import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SectionGroup } from '../../backend-services/request.service';
import { RequestViewPageService } from '../request-view-page.service';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-section-list-item',
  templateUrl: './section-list-item.component.html',
  styleUrls: ['./section-list-item.component.scss'],
})
export class SectionListItemComponent {
  isExpanded = false;
  @Input() sectionGroup!: SectionGroup;
  @Input() sectionGroupIndex!: number;

  constructor(
    public requestViewPageService: RequestViewPageService,
    private translocoService: TranslocoService
  ) {
    this.requestViewPageService.selectedSectionChangedEvent.subscribe(() => {
      if (
        this.requestViewPageService.selectedSectionMetaData
          ?.sectionGroupIndex == this.sectionGroupIndex
      ) {
        this.isExpanded = true;
      }
    });
  }

  toggleExpanded() {
    this.isExpanded = !this.isExpanded;
  }

  getTranslatedDimensionStr(dimensionStr: string): string {
    if (dimensionStr.includes('bleed:')) {
      return dimensionStr.replace(
        'bleed:',
        this.translocoService.translate('Bleed')+ ":"
      );
    }
    return dimensionStr;
  }
}
