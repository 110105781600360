import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RequestViewPageService } from './request-view-page.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FileUploadModalV4Service } from './file-upload-modal-v4/file-upload-modal-v4.service';
import { AuthService } from '../backend-services/auth.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-request-view-page-v4',
  templateUrl: './request-view-page-v4.component.html',
  styleUrls: ['./request-view-page-v4.component.scss'],
})
export class RequestViewPageV4Component {
  constructor(
    private route: ActivatedRoute,
    public requestViewPageService: RequestViewPageService,
    public sanitizer: DomSanitizer,
    private fileUploadModalV4Service: FileUploadModalV4Service,
    private authService: AuthService,
    private toastr: ToastrService
  ) {
    this.route.paramMap.subscribe((params) => {
      this.requestViewPageService.producerHash =
        this.route.snapshot.queryParamMap.get('producer_hash');

      this.requestViewPageService.requestPublicHash =
        params.get('publicHash') || '';

      this.requestViewPageService.fetchRequest();
    });
  }

  get showUploadInput() {
    // only show to clients
    if (
      this.requestViewPageService.producerHash ||
      this.authService.isAuthenticated.value
    ) {
      return false;
    }

    if (
      !this.requestViewPageService.selectedSection ||
      this.requestViewPageService.selectedSection?.max_file_count <=
        this.requestViewPageService.selectedSection?.uploaded_files.length
    ) {
      return false;
    }

    // section must be editable
    return (
      this.requestViewPageService.selectedSection.status === 1 ||
      this.requestViewPageService.selectedSection.status === 101 ||
      this.requestViewPageService.selectedSection.status === 102 ||
      this.requestViewPageService.selectedSection.status === 202 ||
      this.requestViewPageService.selectedSection.status === 302
    );
  }

  onFileChange(event: any) {
    const files = event.target.files;
    const currentFileIndex = 0;

    if (
      this.requestViewPageService.selectedSection!.max_file_count <
      files.length +
        this.requestViewPageService.selectedSection?.uploaded_files.length
    ) {
      this.toastr.error(
        `Maximum allowed file count: ${
          this.requestViewPageService.selectedSection!.max_file_count
        }) exceeded`
      );
    } else if (files.length === 1) {
      this.fileUploadModalV4Service.modalEvents.next({
        type: 'upload-file',
        file: files[currentFileIndex],
        auto_close: false,
        request_hash: this.requestViewPageService.requestPublicHash,
        section_id: this.requestViewPageService.selectedSection!.id,
      });
    } else {
      this.handleRecursiveUpload(files, currentFileIndex);
    }
  }

  handleRecursiveUpload(files: any[], currentFileIndex: number) {
    const toastHandle = this.toastr.info(
      `Handling file ${currentFileIndex + 1} of ${files.length}`,
      undefined,
      { disableTimeOut: true }
    );

    const subscription = this.fileUploadModalV4Service.modalEvents.subscribe(
      (ev) => {
        if (ev.type === 'close') {
          toastHandle.toastRef.close();

          subscription.unsubscribe();

          if (ev.from_auto_close && currentFileIndex < files.length - 1) {
            this.handleRecursiveUpload(files, currentFileIndex + 1);
          }
        }
      }
    );

    this.fileUploadModalV4Service.modalEvents.next({
      type: 'upload-file',
      file: files[currentFileIndex],
      auto_close: currentFileIndex < files.length - 1,
      request_hash: this.requestViewPageService.requestPublicHash,
      section_id: this.requestViewPageService.selectedSection!.id,
    });
  }
}
