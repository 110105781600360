<div
  [class]="
    'mt-2 flex gap-2 pl-3 pr-5 py-2 items-center rounded-full w-fit ' + bgColor
  "
  *ngIf="variant === 1"
>
  <img
    class="w-4 h-4"
    [src]="'assets/imgs/icons/' + statusIcon"
    alt="hour glass icon"
  />
  <div class="font-semibold text-white text-11px">
    {{ statusText }}
  </div>
</div>

<div class="flex items-center gap-1 ml-2 min-w-[140px]" *ngIf="variant === 2">
  <div [class]="'w-2 h-2 rounded-full ' + bgColor"></div>
  <div
    [class]="'text-11px font-semibold leading-17.6px text-nowrap ' + textColor"
  >
    {{ statusText }}
  </div>
</div>

<div class="flex items-center gap-2 mt-4" *ngIf="variant === 3">
  <div [class]="'p-1 rounded-lg ' + bgColor">
    <img alt="" [src]="'assets/imgs/icons/' + statusIcon" />
  </div>
  <div [class]="'font-semibold text-17px leading-27.2px ' + textColor">
    {{ statusText }}
  </div>
</div>

<div class="w-fit" *ngIf="variant === 4">
  <div [class]="'p-1 rounded-lg ' + bgColor">
    <img alt="" [src]="'assets/imgs/icons/' + statusIcon" />
  </div>
</div>

<!--this is done to force tailwind to generate class names-->
<div class="hidden bg-gray7 text-gray7"></div>
<div class="hidden bg-orange1 text-orange1"></div>
<div class="hidden bg-red3 text-red3"></div>
<div class="hidden bg-light-blue2 text-light-blue2"></div>
<div class="hidden bg-light-blue4 text-light-blue4"></div>
<div class="hidden bg-blue4 text-blue4"></div>
<div class="hidden bg-green2 text-green2"></div>
<div class="hidden bg-green4 text-green4"></div>
<div class="hidden bg-green3 text-green3"></div>
<div class="hidden bg-purple1 text-purple1"></div>
<div class="hidden bg-purple2 text-purple2"></div>
<div class="hidden bg-primary text-primary"></div>
