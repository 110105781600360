<app-custom-modal
  [modalEvents]="editUserModalService.modalEvents"
  classes="max-w-2xl font-Satoshi"
  *transloco="let t"
  maxHeight="600px"
>
  <h3
    header
    class="font-Montserrat text-xl font-bold"
    transloco="Benutzer bearbeiten"
    *ngIf="editUserModalService.id"
  >
    Benutzer bearbeiten
  </h3>
  <h3
    header
    class="font-Montserrat text-xl font-bold"
    transloco="Neuer Benutzer"
    *ngIf="!editUserModalService.id"
  >
    Neuer Benutzer
  </h3>
  <div content-body class="flex flex-col gap-6">
    <div *isSuperAdmin class="flex flex-col gap-[5px]">
      <div *ngIf="!editUserModalService.isNewWithCustomer">
        <app-label class="font-bold text-15px" transloco="Customer"
          >Customer</app-label
        >
        <div
          class="border border-gray3 rounded-lg focus:border-gray3 focus:ring-2 focus:ring-gray3 focus:outline-none p-3 text-sm w-full font-Satoshi text-15px"
          *ngIf="editUserModalService.id"
        >
          {{ editUserModalService.selectedCustomer?.label || " - " }}
        </div>
        <app-custom-dropdown
          *ngIf="!editUserModalService.id"
          [buttonText]="editUserModalService.selectedCustomer?.label || ''"
        >
          <li
            *ngFor="let customer of editUserModalService.allCustomers"
            class="py-2 px-4 min-w-[400px] cursor-pointer"
            (click)="changeCustomerId(customer.id)"
          >
            {{ customer.label }}
          </li>
        </app-custom-dropdown>
      </div>
    </div>
    <app-form-input
      [label]="t('Firstname')"
      [placeholder]="t('Firstname eingeben') + '...'"
      [fc]="editUserModalService.firstNameControl"
      [hasError]="
        editUserModalService.firstNameControl.invalid &&
        editUserModalService.firstNameControl.touched
      "
      [errorMessage]="t('Invalid Firstname')"
    ></app-form-input>
    <app-form-input
      [label]="t('Lastname')"
      [placeholder]="t('Lastname eingeben') + '...'"
      [fc]="editUserModalService.lastNameControl"
      [hasError]="
        editUserModalService.lastNameControl.invalid &&
        editUserModalService.lastNameControl.touched
      "
      [errorMessage]="t('Invalid Lastname')"
    ></app-form-input>
    <app-form-input
      [label]="t('E-Mail')"
      [placeholder]="t('E-Mail eingeben') + '...'"
      [fc]="editUserModalService.emailControl"
      [hasError]="
        editUserModalService.emailControl.invalid &&
        editUserModalService.emailControl.touched
      "
      [errorMessage]="t('Invalid E-Mail')"
    ></app-form-input>
    <div>
      <div class="flex flex-col">
        <app-label class="pb-[5px]" attrFor="role-select">
          {{ t("Role") }}
        </app-label>
        <select
          id="role-select"
          class="border border-gray3 rounded-lg focus:border-gray3 focus:ring-2 focus:ring-gray3 focus:outline-none p-3 text-sm w-full font-Satoshi text-15px"
          [formControl]="editUserModalService.roleIdControl"
        >
          <option
            *ngFor="let role of editUserModalService.filteredRoles"
            [value]="role.id"
          >
            {{ role.name }}
          </option>
        </select>
        <div
          *ngIf="
            editUserModalService.roleIdControl.invalid &&
            editUserModalService.roleIdControl.touched
          "
          class="text-sm text-semantic-danger-500"
        >
          {{ t("Invalid E-Mail") }}}
        </div>
      </div>
    </div>
    <div>
      <div class="flex gap-4 items-center">
        <label
          for="email_notification_switch"
          class="text-15px font-bold text-primary"
          transloco="Benutzer aktiviert"
        >
          Benutzer aktiviert
        </label>
        <label class="flex items-center cursor-pointer mt-1">
          <input
            id="email_notification_switch"
            type="checkbox"
            value=""
            class="sr-only peer"
            [formControl]="editUserModalService.enabledControl"
          />
          <div
            class="relative w-16 h-9 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-gray10 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[4px] after:start-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-7 after:w-7 after:transition-all peer-checked:bg-green6 peer-checked:after:bg-white"
          ></div>
        </label>
      </div>
    </div>
    <div>
      <div class="flex gap-4 items-center">
        <label
          for="email_notification_switch"
          class="text-15px font-bold text-primary"
          transloco="Passwort setzen"
        >
          Passwort setzen
        </label>
        <label class="flex items-center cursor-pointer mt-1">
          <input
            id="set_password_switch"
            type="checkbox"
            value=""
            class="sr-only peer"
            [formControl]="editUserModalService.setPasswordControl"
          />
          <div
            class="relative w-16 h-9 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-gray10 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[4px] after:start-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-7 after:w-7 after:transition-all peer-checked:bg-green6 peer-checked:after:bg-white"
          ></div>
        </label>
      </div>
    </div>
    <div
      class="p-2"
      *ngIf="
        !editUserModalService.id &&
        !editUserModalService.setPasswordControl.value
      "
      transloco="Der neue Benutzer erhält eine E-Mail zum Setzen seines Passwortes."
    >
      Der neue Benutzer erhält eine E-Mail zum Setzen seines Passwortes.
    </div>
    <app-form-input
      *ngIf="editUserModalService.setPasswordControl.value === true"
      [label]="t('Password')"
      [placeholder]="t('Password eingeben') + '...'"
      [fc]="editUserModalService.passwordControl"
      [hasError]="
        editUserModalService.passwordControl.invalid &&
        editUserModalService.passwordControl.touched
      "
      [errorMessage]="t('Invalid Password')"
    ></app-form-input>
    <p
      *ngIf="editUserModalService.setPasswordControl.value === true"
      transloco="Password must be at least 8 characters long and include at least one uppercase letter, one number, and one special character"
      class="text-sm -mt-2 text-blue2 font-medium"
    >
      Password must be at least 8 characters long and include at least one
      uppercase letter, one number, and one special character
    </p>
  </div>
  <div footer class="flex w-full justify-between">
    <button
      class="tw-btn-ghost bg-white"
      transloco="Abbrechen"
      (click)="editUserModalService.goBackCheck()"
    >
      Abbrechen
    </button>
    <button
      *ngIf="editUserModalService.id"
      class="tw-btn-blue"
      transloco="Löschen"
      (click)="editUserModalService.openDeleteConfirmModal()"
    >
      Löschen
    </button>
    <button
      class="tw-btn-secondary"
      transloco="Speichern"
      (click)="editUserModalService.handleSubmit()"
    >
      Speichern
    </button>
  </div>
</app-custom-modal>
<app-custom-modal
  [modalEvents]="editUserModalService.deleteConfirmModalEvents"
  classes="max-w-lg"
>
  <h1 header class="text-xl font-semibold" transloco="Delete Confirmation">
    Delete Confirmation
  </h1>
  <div
    content-body
    class="w-full flex flex-col items-center justify-center py-6 text-center"
    *transloco="let t"
  >
    <svg
      class="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
      />
    </svg>
    <h3 class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
      <span transloco="Are you sure you want to remove this user?"
        >Are you sure you want to remove this user?</span
      >
    </h3>
  </div>
  <div footer class="w-full justify-between flex">
    <button
      type="button"
      class="tw-btn-ghost bg-white min-w-[130px]"
      transloco="No"
      (click)="editUserModalService.closeDeleteConfirmModal()"
      [disabled]="editUserModalService.isDeleting"
    >
      No
    </button>
    <button
      type="button"
      class="tw-btn-ghost bg-semantic-danger-500 text-white min-w-[130px]"
      transloco="Yes"
      [disabled]="editUserModalService.isDeleting"
      (click)="editUserModalService.handleDeleteConfirm()"
    >
      Yes
    </button>
  </div>
</app-custom-modal>
