import { Pipe, PipeTransform } from '@angular/core';
import dayjs from "dayjs";
import 'dayjs/locale/de';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import {TranslocoService} from "@ngneat/transloco";


dayjs.extend(localizedFormat);



@Pipe({
  name: 'localeDateFormat'
})
export class LocaleDateFormatPipe implements PipeTransform {
  constructor(private translocoService: TranslocoService) {

  }

  transform(value?: string, ...args: string[]) {
    dayjs.locale(this.translocoService.getActiveLang());
    const format = args[0] || "DD. MMMM YY, hh:mm a"
    if (!value) {
      return value;
    }
    const date = dayjs(value);
    return  date.format(format);
  }

}
