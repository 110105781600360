<app-custom-modal [modalEvents]="producerPickerModalV4Service.modalEvents"  classes="w-full lg:w-2/3 2xl:w-1/2 max-w-5xl max-h-full">
  <h3
    header
    class="font-Montserrat text-xl font-bold"
    transloco="Select Producer"
  >
    Select Producer
  </h3>
  <div content-body>
    <div class="my-4">
      <label
        for="customer"
        class="block mb-2 text-xs 2xl:text-sm font-medium text-gray-900 dark:text-white" transloco="Producer"
      >Producer</label
      >
      <select
        id="customer"
        class="border border-gray3 rounded-lg focus:border-gray15 focus:ring-1 focus:ring-gray15 focus:outline-none p-3 text-sm w-full font-Satoshi text-15px font-medium text-primary"
        [formControl]="producerPickerModalV4Service.producerControl"
      >
        <option
          [value]="producer.id"
          *ngFor="let producer of producerPickerModalV4Service.producerList"
        >
          {{ producer.company_name }}
        </option>
      </select>
    </div>
    <div class="w-full mt-4">
      <label
        class="block mb-2 text-xs 2xl:text-sm font-medium text-gray-900 dark:text-white"
        for="recipient" transloco="Add Recipients"
      >Add Recipients</label
      >
      <select
        id="recipient"
        class="border border-gray3 rounded-lg focus:border-gray15 focus:ring-1 focus:ring-gray15 focus:outline-none p-3 text-sm w-full font-Satoshi text-15px font-medium text-primary"
        [formControl]="contactSelectInput"
      >
        <option disabled [value]="''" selected transloco="Select">Select</option>
        <option
          *ngFor="
                let contact of producerPickerModalV4Service.remainingContactList
              "
          [value]="contact.id"
        >
          {{ contact.first_name }} {{ contact.last_name }} -
          {{ contact.email }}
        </option>
      </select>
    </div>
    <div class="flex flex-wrap gap-4 py-2">
      <div
        *ngFor="let contact of producerPickerModalV4Service.selectedContacts"
        class="bg-white px-4 py-2 rounded-lg border-2"
      >
        {{ contact.email }}
        <button
          class="p-2"
          (click)="producerPickerModalV4Service.removeRecipient(contact.id.toString())"
        >
          x
        </button>
      </div>
    </div>
  </div>
  <div footer class="flex justify-between w-full items-center">
    <button
      type="button"
      (click)="initProducerPickerModalClose()"
      [disabled]="producerPickerModalV4Service.isSubmitting"
      class="tw-btn-secondary-outline"
      transloco="Cancel"
    >
      Cancel
    </button>
    <button
      type="button"
      (click)="producerPickerModalV4Service.handleSubmit()"
      [disabled]="producerPickerModalV4Service.isSubmitting"
      class="tw-btn-secondary"
      transloco="Save"
    >
      Save
    </button>
  </div>
</app-custom-modal>
