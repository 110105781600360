<app-public-navbar *ngIf="!authService.isAuthenticated.value"></app-public-navbar>
<app-nav-bar *ngIf="authService.isAuthenticated.value"></app-nav-bar>
<section class="mb-16 duration-200 bg-gray10 font-Satoshi px-4">
  <div
    class="container mx-auto text-white py-3 my-6 text-center font-medium text-base leading-46px -tracking-0.022em rounded-3xl {{
      topNotificationBanner?.bgClass
    }}"
    *ngIf="topNotificationBanner.show"
    [ngClass]="{ hidden: collapsed }"
  >
    {{ topNotificationBanner.message }}
  </div>
  <div
    class="container mx-auto grid grid-cols-1 xl:grid-cols-10 py-16 gap-6"
    [ngClass]="{ 'py-16': !collapsed, 'py-4': collapsed }"
  >
    <div *ngIf="requestData?.logo?.url">
      <img
        [src]="requestData?.logo?.url"
        alt="grow-logo"
        class="w-full rounded-2xl border"
        [ngClass]="{ 'max-w-[140px]': !collapsed, 'max-w-[80px]': collapsed }"
      />
    </div>
    <div
      class="flex flex-col xl:col-span-4"
      [ngClass]="{ 'py-1': collapsed, 'py-6': !collapsed }"
    >
      <h2
        class="text-blue2 opacity-60 font-medium text-base mb-3"
        transloco="Datenanfrage für:"
      >
        Datenanfrage für:
      </h2>
      <h1
        class="text-secondary-900 font-bold tracking-0.022em mb-4 font-Montserrat"
        [ngClass]="{
          'text-2xl leading-28.4px': collapsed,
          'text-28px leading-48px': !collapsed
        }"
      >
        {{ requestData?.title }}
      </h1>
      <p
        class="text-15px text-gray17 font-normal leading-6 mb-4"
        *ngIf="!collapsed"
      >
        {{ requestData?.description }}
      </p>
      <button
        class="tw-btn-primary w-fit flex items-center gap-3 mb-5"
        *ngIf="canCompleteRequest"
        (click)="completeRequest()"
      >
        <span transloco="Complete request">Complete request</span>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_4675_18646)">
            <path
              d="M10 18.75C7.67936 18.75 5.45376 17.8281 3.81282 16.1872C2.17187 14.5462 1.25 12.3206 1.25 10C1.25 7.67936 2.17187 5.45376 3.81282 3.81282C5.45376 2.17187 7.67936 1.25 10 1.25C12.3206 1.25 14.5462 2.17187 16.1872 3.81282C17.8281 5.45376 18.75 7.67936 18.75 10C18.75 12.3206 17.8281 14.5462 16.1872 16.1872C14.5462 17.8281 12.3206 18.75 10 18.75ZM10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20Z"
              fill="white"
            />
            <path
              d="M13.7125 6.2125C13.7036 6.22113 13.6952 6.23031 13.6875 6.24L9.34625 11.7712L6.73 9.15375C6.55228 8.98815 6.31722 8.898 6.07435 8.90228C5.83147 8.90657 5.59974 9.00496 5.42797 9.17672C5.25621 9.34849 5.15782 9.58022 5.15353 9.8231C5.14925 10.066 5.2394 10.301 5.405 10.4788L8.7125 13.7875C8.8016 13.8764 8.90771 13.9465 9.02448 13.9936C9.14125 14.0406 9.26631 14.0637 9.39218 14.0613C9.51806 14.059 9.64217 14.0313 9.75712 13.98C9.87207 13.9286 9.9755 13.8547 10.0612 13.7625L15.0513 7.525C15.2212 7.34666 15.3141 7.10862 15.3099 6.86234C15.3057 6.61605 15.2047 6.38131 15.0288 6.20887C14.8529 6.03643 14.6162 5.94014 14.3699 5.94081C14.1236 5.94149 13.8874 6.03909 13.7125 6.2125Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_4675_18646">
              <rect width="20" height="20" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>
      <a
        *ngIf="
          requestViewPageService.isAgency || requestViewPageService.producerHash
        "
        [href]="'/api/requests/download_files/' + requestData?.public_hash"
        class="tw-btn-secondary max-w-[500px] flex items-center gap-3 justify-center font-bold text-15px leading-6"
        [ngClass]="{
          'my-1': collapsed,
          'my-6': !collapsed,
          'pointer-events-none opacity-60':
            requestViewPageService.waitingDownload
        }"
        (click)="requestViewPageService.addFakeDownloadDelay()"
      >
        <span transloco="Daten gesammelt herunterladen"
          >Daten gesammelt herunterladen</span
        >
        ({{ requestViewPageService.globalSectionList.length }})
        <img src="assets/imgs/icons/download_icon.svg" alt="" />
      </a>
    </div>
    <div
      class="xl:col-span-3 border rounded-3xl px-5 w-full flex flex-col justify-center bg-white"
      [ngClass]="{ 'py-4': collapsed }"
    >
      <div
        class="flex flex-col pb-4 gap-[2px]"
        [ngClass]="{ 'pt-4': !collapsed }"
      >
        <div class="text-blue2 opacity-60 font-medium text-sm">Kunde:</div>
        <div class="text-secondary-900 font-bold text-15px">
          {{ requestData?.client?.customer?.label }}
        </div>
      </div>
      <div class="flex flex-col pb-4 gap-[2px]" *ngIf="!collapsed">
        <div
          class="text-blue2 opacity-60 font-medium text-sm"
          transloco="Agentur:"
        >
          Agentur:
        </div>
        <div class="text-secondary-900 font-bold text-15px">
          {{ requestData?.client?.company_name }}
        </div>
      </div>
      <div class="flex flex-col pb-4 gap-[2px]" *ngIf="!collapsed">
        <div class="text-blue2 opacity-60 font-medium text-sm">
          Angefordert am:
        </div>
        <div class="text-secondary-900 font-bold text-15px">
          {{
            requestData?.created_at | localeDateFormat: "DD. MMMM YYYY"
          }}
        </div>
      </div>
      <div
        class="flex flex-col pb-4 gap-[2px]"
        [ngClass]="{ 'pb-4': !collapsed }"
      >
        <div class="text-blue2 opacity-60 font-medium text-sm">
          Abgabedatum:
        </div>
        <div
          class="text-red6 font-bold text-15px"
          *ngIf="requestData?.due_date"
        >
          {{ requestData?.due_date | localeDateFormat: "DD. MMMM YYYY" }}
        </div>
        <div
          class="text-secondary-900 font-bold text-15px"
          *ngIf="!requestData?.due_date"
        >
          -
        </div>
      </div>
      <div class="flex flex-col pb-4 gap-[2px]" *ngIf="!collapsed">
        <div class="text-blue2 opacity-60 font-medium text-sm">
          Projektnummer:
        </div>
        <div class="text-secondary-900 font-bold text-15px">
          {{
            requestData?.project_number !== undefined
              ? requestData?.project_number
              : "-"
          }}
        </div>
      </div>
    </div>
    <div
      class="xl:col-span-2 border rounded-3xl py-4 px-5 w-full flex flex-col justify-center bg-white"
    >
      <div
        class="text-blue2 opacity-60 font-medium text-sm"
        transloco="Gesamtstatus:"
      >
        Gesamtstatus:
      </div>
      <app-status-display
        [variant]="1"
        [statusId]="
          requestData?.metadata?.overall_status_id || requestData?.request_status_id_gen || requestData?.request_status_id!
        "
        *ngIf="
          requestData?.metadata?.overall_status_id || requestData?.request_status_id_gen || requestData?.request_status_id!
        "
      ></app-status-display>
      <ng-container *ngIf="!collapsed">
        <div
          class="mt-5 text-primary opacity-60 font-semibold text-sm leading-22.4px tracking-0.6px uppercase pb-2 border-b border-neutral_gray-300 font-Montserrat"
          transloco="Statistik"
        >
          Statistik
        </div>
        <div class="flex justify-between pt-3">
          <div class="flex items-center gap-2">
            <div class="w-3 h-3 rounded-full bg-gray7"></div>
            <div
              class="text-xs text-primary font-normal leading-19.2px"
              transloco="Warten auf Upload"
            >
              Warten auf Upload
            </div>
          </div>
          <div class="text-xs text-primary font-semibold leading-19.2px">
            {{ requestViewPageService.globalSectionStatusCounter[1] || 0 }}
          </div>
        </div>
        <div class="flex justify-between pt-3">
          <div class="flex items-center gap-2">
            <div class="w-3 h-3 rounded-full bg-orange1"></div>
            <div
              class="text-xs text-primary font-normal leading-19.2px"
              transloco="Bereit für Versand"
            >
              Bereit für Versand
            </div>
          </div>
          <div class="text-xs text-primary font-semibold leading-19.2px">
            {{ requestViewPageService.globalSectionStatusCounter[101] || 0 }}
          </div>
        </div>
        <div class="flex justify-between pt-3">
          <div class="flex items-center gap-2">
            <div class="w-3 h-3 rounded-full bg-light-blue2"></div>
            <div
              class="text-xs text-primary font-normal leading-19.2px"
              transloco="Warten auf Agentur"
            >
              Warten auf Agentur
            </div>
          </div>
          <div class="text-xs text-primary font-semibold leading-19.2px">
            {{ requestViewPageService.globalSectionStatusCounter[2] || 0 }}
          </div>
        </div>
        <div class="flex justify-between pt-3 opacity-40">
          <div class="flex items-center gap-2">
            <div class="w-3 h-3 rounded-full bg-primaryBtn"></div>
            <div
              class="text-xs text-primary font-normal leading-19.2px"
              transloco="Bürstenabzug"
            >
              Bürstenabzug
            </div>
          </div>
          <div class="text-xs text-primary font-semibold leading-19.2px">
            {{ proofFileSectionsCount }}
          </div>
        </div>
        <div class="flex justify-between pt-3">
          <div class="flex items-center gap-2">
            <div class="w-3 h-3 rounded-full bg-green2"></div>
            <div
              class="text-xs text-primary font-normal leading-19.2px"
              transloco="Freigabe"
            >
              Freigabe
            </div>
          </div>
          <div class="text-xs text-primary font-semibold leading-19.2px">
            {{ successSectionsCount }}
          </div>
        </div>
        <div class="flex justify-between pt-3">
          <div class="flex items-center gap-2">
            <div class="w-3 h-3 rounded-full bg-red3"></div>
            <div
              class="text-xs text-primary font-normal leading-19.2px"
              transloco="Daten Fehler"
            >
              Daten Fehler
            </div>
          </div>
          <div class="text-xs text-primary font-semibold leading-19.2px">
            {{ errorSectionsCount }}
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="flex gap-2 items-center">
    <hr class="flex-grow border-none h-[2px] bg-white" />
    <button
      class="flex justify-center items-center p-4 border rounded-full hover:bg-gray-50 transition-transform duration-200"
      (click)="toggleCollapsed()"
      [ngClass]="{ 'rotate-180': !collapsed }"
    >
      <img
        src="/assets/imgs/icons/bs-chevron-down.svg"
        alt="expand / collapse"
      />
    </button>
    <hr class="flex-grow border-none h-[2px] bg-white" />
  </div>
</section>
