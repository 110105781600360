import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TransformNamePipe } from './transform-name.pipe';
import { TransformTimestampPipe } from './transform-timestamp.pipe';
import { FileSizePipe } from './file-size.pipe';
import { NativeNumberPipe } from './native-number.pipe';
import { LocaleDateFormatPipe } from './locale-date-format.pipe';

@NgModule({
  declarations: [
    TransformNamePipe,
    TransformTimestampPipe,
    FileSizePipe,
    NativeNumberPipe,
    LocaleDateFormatPipe,
  ],
  imports: [CommonModule],
  exports: [
    TransformNamePipe,
    TransformTimestampPipe,
    FileSizePipe,
    NativeNumberPipe,
    LocaleDateFormatPipe,
  ],
})
export class PipesModule {}
