import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ModalEvents } from '../../shared-components/custom-modal/custom-modal.component';
import { User, UserService } from '../../backend-services/user.service';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Role, RoleService } from '../../backend-services/role.service';
import {
  Customer,
  CustomerService,
} from '../../backend-services/customer.service';
import { AuthService } from '../../backend-services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class EditUserModalService {
  availableRoles: Role[] = [];
  allCustomers: Customer[] = [];
  // filter roles according to customer
  filteredRoles: Role[] = [];

  // track if there are unsaved data
  initialData: any = undefined;

  modalEvents = new Subject<
    | ModalEvents
    | { type: 'new'; customer_id?: number }
    | { type: 'new-with-customer'; user: any }
    | { type: 'user-data-updated'; user: any }
    | { type: 'edit'; user: User }
  >();

  deleteConfirmModalEvents = new Subject<ModalEvents>();
  isDeleting = false;

  isNewWithCustomer = false;

  userForm = new FormGroup({
    first_name: new FormControl('', [
      Validators.required,
      Validators.minLength(1),
    ]),
    last_name: new FormControl('', [
      Validators.required,
      Validators.minLength(1),
    ]),
    email: new FormControl('', [Validators.required, Validators.email]),
    enabled: new FormControl(true),
    set_password: new FormControl(true),
    role_id: new FormControl(3, [Validators.required]),
    customer_id: new FormControl('', []),
  });

  passwordControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/),
  ]);

  isSuperAdmin = false;
  id: number | null = null;

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private roleService: RoleService,
    private customerService: CustomerService,
    private toastr: ToastrService,
    private translocoService: TranslocoService
  ) {
    this.modalEvents.subscribe((ev) => {
      switch (ev.type) {
        case 'init-close':
          this.modalEvents.next({ type: 'close' });
          break;
        case 'new-with-customer':
          this.isNewWithCustomer = true;
          this.firstNameControl.setValue(ev.user.first_name);
          this.lastNameControl.setValue(ev.user.last_name);
          this.emailControl.setValue(ev.user.email);
          this.enabledControl.setValue(ev.user.enabled);
          this.setPasswordControl.setValue(ev.user.set_password);
          this.roleIdControl.setValue(ev.user.role_id);
          this.customerIdControl.setValue(ev.user.customer_id);
          this.passwordControl.setValue(ev.user.password);

          this.modalEvents.next({ type: 'open' });
          break;
        case 'edit':
          this.initialData = ev.user;
          this.id = ev.user.id;
          this.firstNameControl.setValue(ev.user.first_name);
          this.lastNameControl.setValue(ev.user.last_name);
          this.emailControl.setValue(ev.user.email);
          this.enabledControl.setValue(ev.user.enabled);
          this.setPasswordControl.setValue(false);
          this.roleIdControl.setValue(ev.user.role_id);
          this.customerIdControl.setValue(ev.user.customer_id);

          this.modalEvents.next({ type: 'open' });
          break;
        case 'new':
          this.modalEvents.next({ type: 'open' });
          this.id = null;

          if (ev.customer_id) {
            this.customerIdControl.setValue(ev.customer_id);
          }
          break;
        case 'close':
          this.passwordControl.reset();
          this.userForm.reset();
          this.id = null;
          this.isNewWithCustomer = false;
      }
    });

    this.authService.isSuperAdmin.subscribe({
      next: (isSuperAdmin) => {
        this.isSuperAdmin = isSuperAdmin;
        this.filterRoles();

        // get customer list if logged in user is SuperAdmin
        if (isSuperAdmin) {
          this.fetchCustomers();
        }
      },
    });

    // fetch available roles
    this.roleService.getRoles().subscribe({
      next: (res) => {
        if (res.success) {
          this.availableRoles = res.data;
          this.filterRoles();
        }
      },
      error: (res) => {
        console.log(res.error);
      },
    });

    // filter roles on customer id change
    this.customerIdControl.valueChanges.subscribe({
      next: () => {
        this.filterRoles();
      },
    });

    // validate customerIdControl on role change
    this.roleIdControl.valueChanges.subscribe({
      next: () => {
        this.customerIdControl.updateValueAndValidity();
      },
    });
  }

  fetchCustomers() {
    this.customerService.getCustomers().subscribe({
      next: (res) => {
        if (res.success) {
          this.allCustomers = res.data;
        }
      },
    });
  }

  get firstNameControl() {
    return this.userForm.get('first_name') as AbstractControl;
  }

  get lastNameControl() {
    return this.userForm.get('last_name') as AbstractControl;
  }

  get emailControl() {
    return this.userForm.get('email') as AbstractControl;
  }

  get enabledControl() {
    return this.userForm.get('enabled') as FormControl;
  }

  get setPasswordControl() {
    return this.userForm.get('set_password') as FormControl;
  }

  get roleIdControl() {
    return this.userForm.get('role_id') as FormControl;
  }

  get customerIdControl() {
    return this.userForm.get('customer_id') as AbstractControl;
  }

  get selectedRole(): Role | null {
    const roleIdControl = this.userForm?.get('role_id');
    if (roleIdControl && roleIdControl.value) {
      return (
        this.availableRoles.find((r) => r.id == this.roleIdControl.value) ??
        null
      );
    }

    return null;
  }

  get selectedCustomer() {
    const customerId = this.customerIdControl.value;
    return this.allCustomers.find((c) => c.id === customerId);
  }

  userFormLoading = false;

  fetchUserData() {
    if (this.id) {
      this.userFormLoading = true;
      this.userForm.disable();

      this.userService.getUserById(this.id).subscribe({
        next: (res) => {
          if (res) {
            this.userFormLoading = false;

            // set form values
            this.firstNameControl.setValue(res.first_name);
            this.lastNameControl.setValue(res.last_name);
            this.emailControl.setValue(res.email);
            this.enabledControl.setValue(res.enabled);
            this.roleIdControl.setValue(res.role_id);
            this.customerIdControl.setValue(res.customer_id);
            this.setPasswordControl.setValue(false);

            this.userForm.enable();

            this.initialData = this.userForm.value;
          }
        },
        error: (err) => {
          console.log(err);
          this.toastr.error(this.translocoService.translate(`User not found`));
        },
      });
    }
  }

  /**
   * Filter roles based on customer id & logged in user type
   */
  filterRoles() {
    // show roles that are available to customer only  if customer_id is selected
    if (!this.isSuperAdmin) {
      // show all available roles Except SuperAdmin when user is not super admin
      this.filteredRoles = this.availableRoles.filter(
        (r) =>
          (!r.customer_id && r.name !== 'SuperAdmin') ||
          r.customer_id == this.customerIdControl.value
      );
    } else if (!!this.customerIdControl.value) {
      /*
       this is part for SuperAdmin users only
       if customer id is selected show roles available for that customer
       */
      this.filteredRoles = this.availableRoles.filter(
        (r) =>
          (!r.customer_id && r.name !== 'SuperAdmin') ||
          r.customer_id == this.customerIdControl.value
      );
    } else {
      /*
       this is part for SuperAdmin users only
       if customer id is not selected, show all roles
      */
      this.filteredRoles = this.availableRoles;
    }
  }

  handleSubmit() {
    this.userForm.markAllAsTouched();
    this.passwordControl.markAllAsTouched();
    this.customerIdControl.updateValueAndValidity();

    if (
      this.userForm.valid &&
      (!this.setPasswordControl.value ||
        (this.setPasswordControl.value && this.passwordControl.valid))
    ) {
      this.userFormLoading = true;

      let data: any = { ...this.userForm.value };

      if (this.setPasswordControl.value) {
        data.password = this.passwordControl.value;
        data.set_password = true;
      }

      // set customer_id as undefined if it is empty
      if (!data.customer_id) {
        delete data.customer_id;
      }

      if (this.isNewWithCustomer) {
        this.modalEvents.next({ type: 'user-data-updated', user: data });
        this.modalEvents.next({ type: 'close' });
      } else if (!this.id) {
        this.userService.createUser(data).subscribe({
          next: (res) => {
            this.userFormLoading = false;

            if (res.success) {
              this.toastr.success(
                this.translocoService.translate(
                  `Neuer Benutzer wurde erfolgreich erstellt!`
                )
              );
              this.modalEvents.next({ type: 'close' });
            }
          },
          error: (res) => {
            if (!!res.error?.message?.email) {
              this.toastr.error(
                this.translocoService.translate(
                  `Diese E-Mail existiert bereits`
                )
              );
            } else {
              console.log('err', res.error);
              this.toastr.error(
                res.error?.message
                  ? this.translocoService.translate(res.error?.message)
                  : res.error?.error
                  ? this.translocoService.translate(res.error.error)
                  : this.translocoService.translate(`Something went wrong`)
              );
            }
            this.userFormLoading = false;
          },
        });
      } else {
        this.userService.updateUser(this.id, data).subscribe({
          next: (res) => {
            this.userFormLoading = false;
            if (res.success) {
              this.toastr.success(
                this.translocoService.translate(`Ihr Profil wurde gespeichert`)
              );
              this.userForm.markAsUntouched();
              this.modalEvents.next({ type: 'user-data-updated', user: data });
              this.goBack();
            }
          },
          error: (res) => {
            console.log('err', res.error);
            this.toastr.error(
              res.error?.message
                ? this.translocoService.translate(res.error?.message)
                : res.error?.error
                ? this.translocoService.translate(res.error.error)
                : this.translocoService.translate(`Something went wrong`)
            );
            this.userFormLoading = false;
          },
        });
      }
    }
  }

  deleteUser() {
    this.isDeleting = true;
    if (this.id) {
      this.userService.deleteUser(this.id).subscribe(() => {
        this.toastr.info(this.translocoService.translate(`User deleted`));
        this.isDeleting = false;
        this.deleteConfirmModalEvents.next({ type: 'close' });
      });
      this.goBack();
    }
  }

  goBackCheck() {
    if (
      JSON.stringify(this.initialData) !== JSON.stringify(this.userForm.value)
    ) {
      this.goBack();
    } else {
      this.goBack();
    }
  }

  goBack() {
    this.modalEvents.next({ type: 'close' });
  }

  openDeleteConfirmModal() {
    this.deleteConfirmModalEvents.next({ type: 'open' });
  }

  closeDeleteConfirmModal() {
    this.deleteConfirmModalEvents.next({ type: 'close' });
  }

  handleDeleteConfirm() {
    this.deleteUser();
  }
}
