import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Subject } from 'rxjs';
import {
  AppRequest,
  RequestExpectedFile,
  RequestInfoFile,
  RequestSection,
  RequestService,
  SectionGroup,
} from '../../backend-services/request.service';
import dayjs from 'dayjs';
import {
  FileType,
  FileTypeService,
} from '../../backend-services/file-type.service';
import { ToastrService } from 'ngx-toastr';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class EditRequestModalService {
  public readonly modalEvents = new Subject<
    | {
        type: 'new';
        client_id?: number;
        request_folder_id?: number;
        duplicate_with_id?: number;
      }
    | { type: 'edit'; id: number }
    | { type: 'close' }
    | { type: 'updated'; request_folder_id?: string; request_id: number }
  >();
  requestFormGroup: FormGroup;

  availableFileTypes: FileType[] = [];
  selectedCollectionIndex = 0;
  selectedSectionIndex = 0;
  currentRequest?: AppRequest;

  constructor(
    private requestService: RequestService,
    private fb: FormBuilder,
    private fileTypeService: FileTypeService,
    private toastr: ToastrService,
    private translocoService: TranslocoService
  ) {
    this.requestFormGroup = this.buildDefaultRequestForm();

    this.modalEvents.subscribe({
      next: (ev) => {
        switch (ev.type) {
          case 'new':
            // reset all data
            this.resetData();

            this.requestFormGroup = this.buildDefaultRequestForm();

            // set client id if provided
            if (ev.client_id) {
              this.getRequestFormControl('client_id')?.setValue(ev.client_id);
            }

            if (ev.request_folder_id) {
              this.getRequestFormControl('request_folder_id')?.setValue(
                ev.request_folder_id
              );
            }

            if (ev.duplicate_with_id) {
              this.requestService
                .getRequestById(ev.duplicate_with_id)
                .subscribe({
                  next: (res) => {
                    // remove ids from data
                    let newData = {
                      ...res.data,
                      id: undefined,
                      client_id: undefined,
                      contact_id: undefined,
                      title: `${res.data.title} (copy)`,
                      section_groups: res.data.section_groups?.map(
                        (section_group: SectionGroup) => ({
                          ...section_group,
                          id: undefined,
                          created_at: undefined,
                          updated_at: undefined,
                          sections: section_group.sections.map(
                            (section: RequestSection) => ({
                              ...section,
                              id: undefined,
                              created_at: undefined,
                              updated_at: undefined,
                              file_history: [],
                              proof_file: undefined,
                              uploaded_files: [],
                              status: 1,
                              section_comments: [],
                              info_files: section.info_files.map(
                                (info_file) => ({
                                  ...info_file,
                                  id: undefined,
                                  created_at: undefined,
                                  updated_at: undefined,
                                  duplicate_from_info_file: info_file.fileKey,
                                })
                              ),
                              expected_files: section.expected_files.map(
                                (expectFile) => ({
                                  ...expectFile,
                                  id: undefined,
                                  created_at: undefined,
                                  updated_at: undefined,
                                  request_folder_id: undefined,
                                })
                              ),
                            })
                          ),
                        })
                      ),
                    };
                    this.updateDataFromRequest(newData as any);
                  },
                });
            }
            break;
          case 'edit':
            this.requestService.getRequestById(ev.id).subscribe({
              next: (res) => {
                this.updateDataFromRequest(res.data);
              },
            });
            break;

          case 'close':
            this.resetData();
            break;
        }
      },
    });

    this.fileTypeService.getFileTypes({}).subscribe({
      next: (res) => {
        this.availableFileTypes = res.data;
      },
    });
  }

  updateDataFromRequest(data: AppRequest) {
    this.currentRequest = data;
    this.getRequestFormControl('id')?.setValue(data.id);
    this.getRequestFormControl('title')?.setValue(data.title);
    this.getRequestFormControl('request_folder_id')?.setValue(
      data.request_folder_id
    );
    this.getRequestFormControl('project_number')?.setValue(data.project_number);
    this.getRequestFormControl('producer_project_number')?.setValue(data.producer_project_number);
    this.getRequestFormControl('description')?.setValue(data.description);

    if (data.due_date) {
      this.getRequestFormControl('due_date')?.setValue(
        dayjs(data.due_date).format('YYYY-MM-DD')
      );
    }
    this.getRequestFormControl('client_id')?.setValue(data.client_id);

    this.getRequestFormControl('contact_id')?.setValue(data.contact_id);

    this.sectionCollectionsFormArr.clear();
    data.section_groups?.forEach((sectionGroup: SectionGroup) => {
      this.sectionCollectionsFormArr.push(
        this.buildSectionCollectionFormGroup(sectionGroup)
      );
    });
  }

  buildSectionCollectionFormGroup(data?: SectionGroup) {
    return this.fb.group({
      id: this.fb.control(data?.id || undefined),
      title: this.fb.control(
        data?.title || this.translocoService.translate('Unbenanntes Produkt'),
        [Validators.required, Validators.maxLength(255)]
      ),
      hide_header_title_input: this.fb.control(!!data?.title),
      sections: this.fb.array(
        data?.sections.map((section) =>
          this.buildSectionFormGroup(section)
        ) || [this.buildSectionFormGroup()]
      ),
    });
  }

  expectedFilesValidator() {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!(control instanceof FormArray)) {
        return null; // Only validate FormArray controls
      }

      const hasPicked = control.controls.some(
        (group: AbstractControl) => group.get('is_picked')?.value === true
      );

      return hasPicked ? null : { atLeastOnePicked: true };
    };
  }

  buildSectionFormGroup(data?: RequestSection) {
    const section = this.fb.group({
      id: this.fb.control(data?.id || undefined),
      title: this.fb.control(data?.title || '', [
        Validators.required,
        Validators.maxLength(255),
      ]),
      description: this.fb.control(data?.description || '', [
        Validators.maxLength(1000),
      ]),
      max_file_count: this.fb.control(data?.max_file_count || 1, [
        Validators.required,
        Validators.min(1),
      ]),
      allow_multiple_files: this.fb.control(
        !!(data && data.max_file_count > 1),
        [Validators.required]
      ),
      max_file_size: this.fb.control(
        data
          ? data.max_file_size > 1024
            ? data.max_file_size / 1024
            : data.max_file_size
          : 10,
        [Validators.min(1)]
      ),
      file_size_unit: this.fb.control(
        data?.max_file_size ? (data.max_file_size > 2048 ? 'MB' : 'KB') : 'MB'
      ),
      info_files: this.fb.array(
        data?.info_files.map((info_file) =>
          this.buildInfoFilesFormGroup(info_file)
        ) || [],
        [Validators.maxLength(4)]
      ),
      expected_file_picker: this.fb.control(''),
      expected_files: this.fb.array(
        data?.expected_files.map((expected_file) =>
          this.buildExpectedFileFormGroup(expected_file)
        ) || [],
        [Validators.required, this.expectedFilesValidator()]
      ),
      gen_expected_file_validations:
        this.generateExpectedFileValidationFormGroup(
          data?.expected_files[0] || undefined
        ),
      selected_data_type: this.fb.control(data?.metadata?.selected_data_type || undefined),
    });

    section.get('allow_multiple_files')!.valueChanges.subscribe({
      next: (value) => {
        if (value) {
          section.get('max_file_count')?.setValue(2);
        } else {
          section.get('max_file_count')?.setValue(1);
        }
      },
    });

    section.get('expected_file_picker')!.valueChanges.subscribe((value) => {
      if (value) {
        (section.get('expected_files') as FormArray).insert(
          0,
          this.buildExpectedFileFormGroup(undefined, parseInt(value))
        );

        section.get('expected_file_picker')?.setValue('');
      }
    });

    return section;
  }

  buildInfoFilesFormGroup(data?: RequestInfoFile) {
    return this.fb.group({
      id: this.fb.control(data?.id || undefined),
      title: this.fb.control(data?.title || '', [
        Validators.required,
        Validators.maxLength(255),
      ]),
      description: this.fb.control(data?.description || '', [
        Validators.maxLength(1000),
      ]),
      file_type: this.fb.group({
        id: this.fb.control(data?.fileType?.id),
        icon_svg: this.fb.control(data?.fileType?.icon_svg),
        name: this.fb.control(data?.fileType?.name),
      }),
      url: this.fb.control(data?.url || '', []),
      file: this.fb.control(undefined, []),
      uploaded_file_blob: this.fb.control(undefined, []),
      duplicate_from_info_file: this.fb.control(
        (data as any)?.duplicate_from_info_file || undefined,
        []
      ),
    });
  }

  buildExpectedFileFormGroup(
    data?: RequestExpectedFile,
    fileTypeId?: number,
    customTypeData?: { is_custom_type: boolean; name: string },
    isPicked = true
  ) {
    return this.fb.group({
      id: this.fb.control(data?.id || undefined),
      validate_color_space: this.fb.control(!!data?.color_space),
      color_space: this.fb.control(data?.color_space || undefined),
      validate_dimensions: this.fb.control(!!data?.dimensions_height),
      dimensions_height: this.fb.control(data?.dimensions_height || undefined),
      dimensions_width: this.fb.control(data?.dimensions_width || undefined),
      dimensions_bleed: this.fb.control(data?.dimensions_bleed || undefined),
      dimensions_unit: this.fb.control(data?.dimensions_unit || 'px'),
      validate_resolution: this.fb.control(!!data?.resolution),
      resolution: this.fb.control(data?.resolution || undefined),
      proof_required: this.fb.control(!!data?.proof_required || undefined),
      file_type_id: this.fb.control(
        fileTypeId || data?.file_type_id || undefined
      ),
      is_custom_type: this.fb.control(
        !!customTypeData || data?.file_type.is_custom_type || undefined
      ),
      name: this.fb.control(
        customTypeData?.name || data?.file_type.name || undefined
      ),
      is_picked: this.fb.control(isPicked),
    });
  }

  generateExpectedFileValidationFormGroup(data?: RequestExpectedFile) {
    return this.fb.group({
      validate_color_space: this.fb.control(!!data?.color_space),
      color_space: this.fb.control(data?.color_space || undefined),
      validate_dimensions: this.fb.control(!!data?.dimensions_height),
      dimensions_height: this.fb.control(data?.dimensions_height || undefined),
      dimensions_width: this.fb.control(data?.dimensions_width || undefined),
      dimensions_bleed: this.fb.control(data?.dimensions_bleed || undefined),
      dimensions_unit: this.fb.control(data?.dimensions_unit || 'px'),
      validate_resolution: this.fb.control(!!data?.resolution),
      resolution: this.fb.control(data?.resolution || undefined),
      proof_required: this.fb.control(data?.proof_required || undefined),
    });
  }

  getRequestFormControl(name: string) {
    return this.requestFormGroup.get(name) as FormControl | undefined;
  }

  get sectionCollectionsFormArr() {
    return this.requestFormGroup.get('section_collections') as FormArray;
  }

  get requestFolderControl() {
    return this.requestFormGroup.get('request_folder_id')! as FormControl;
  }

  get clientIdControl() {
    return this.requestFormGroup.get('client_id')! as FormControl;
  }

  get contactIdControl() {
    return this.requestFormGroup.get('contact_id')! as FormControl;
  }

  buildDefaultRequestForm() {
    return this.fb.group({
      id: this.fb.control(undefined),
      title: this.fb.control('', [
        Validators.required,
        Validators.maxLength(255),
      ]),
      request_folder_id: this.fb.control(''),
      project_number: this.fb.control('', [Validators.maxLength(255)]),
      producer_project_number: this.fb.control('', [Validators.maxLength(255)]),
      description: this.fb.control('', [Validators.max(1000)]),
      due_date: this.fb.control('', []),
      client_id: this.fb.control('', [Validators.required]),
      contact_id: this.fb.control('', [Validators.required]),
      section_collections: this.fb.array(
        [this.buildSectionCollectionFormGroup()],
        [Validators.required, Validators.minLength(1)]
      ),
    });
  }

  resetData() {
    this.currentRequest = undefined;
    this.sectionCollectionsFormArr.clear();
    this.addNewSectionCollection();
    this.requestFormGroup.reset();
  }

  addNewSectionCollection() {
    this.sectionCollectionsFormArr.push(
      this.buildSectionCollectionFormGroup(),
      { emitEvent: true }
    );
  }

  removeSectionCollection(index: number) {
    this.sectionCollectionsFormArr.removeAt(index, {
      emitEvent: true,
    });
    if (index === this.selectedCollectionIndex) {
      this.selectedCollectionIndex = 0;
      this.selectedCollectionIndex = 0;
    }
  }

  addNewSection(sectionCollection: FormGroup) {
    (sectionCollection.get('sections') as FormArray).push(
      this.buildSectionFormGroup()
    );
    const newSectionIndex = (sectionCollection.get('sections') as FormArray).length - 1;
    const newCollectionIndex = this.selectedCollectionIndex;
    setTimeout(() => {
      this.selectedCollectionIndex = newCollectionIndex;
      this.selectedSectionIndex = newSectionIndex;
    }, 100)

  }

  public handleSubmit() {
    this.requestFormGroup.markAllAsTouched();
    if (this.requestFormGroup.valid) {
      const formData = this.buildRequestFormData();
      this.requestFormGroup.disable({ emitEvent: true });

      if (this.requestFormGroup.get('id')?.value) {
        this.requestService
          .updateRequest(this.requestFormGroup.get('id')?.value, formData)
          .subscribe({
            next: (res) => {
              this.toastr.success(
                this.translocoService.translate(`Request updated successfully!`)
              );
              this.modalEvents.next({ type: 'close' });
              this.modalEvents.next({
                type: 'updated',
                request_id: this.requestFormGroup.get('id')?.value!,
                request_folder_id: formData
                  .get('request_folder_id')
                  ?.toString(),
              });
              this.requestFormGroup.enable({ emitEvent: true });
            },
            error: (err) => {
              this.toastr.error(
                err.error?.message || JSON.stringify(err.error)
              );
              this.requestFormGroup.enable({ emitEvent: true });
            },
          });
      } else {
        this.requestService.createRequest(formData).subscribe({
          next: (res) => {
            this.toastr.success(
              this.translocoService.translate(`Request Created Successfully!`)
            );
            const requestId = res.data.id;
            this.modalEvents.next({ type: 'close' });
            this.modalEvents.next({
              type: 'updated',
              request_id: requestId,
              request_folder_id: formData.get('request_folder_id')?.toString(),
            });
            this.requestFormGroup.enable({ emitEvent: true });
          },
          error: (err) => {
            this.toastr.error(err.error?.message || JSON.stringify(err.error));
            this.requestFormGroup.enable({ emitEvent: true });
          },
        });
      }
    } else {
      this.toastr.error(this.translocoService.translate('Validation error'));
    }
  }

  buildRequestFormData() {
    const formData = new FormData();

    // add main info
    if (this.requestFormGroup.get('id')?.value) {
      formData.append('id', this.requestFormGroup.get('id')?.value);
    }

    formData.append('title', this.requestFormGroup.get('title')?.value);

    if (this.requestFormGroup.get('project_number')?.value) {
      formData.append(
        'project_number',
        this.requestFormGroup.get('project_number')?.value
      );
    }

    if (this.requestFormGroup.get('producer_project_number')?.value) {
      formData.append(
        'producer_project_number',
        this.requestFormGroup.get('producer_project_number')?.value
      );
    }

    if (this.requestFormGroup.get('request_folder_id')?.value) {
      formData.append(
        'request_folder_id',
        this.requestFormGroup.get('request_folder_id')?.value
      );
    }

    if (this.requestFormGroup.get('description')?.value) {
      formData.append(
        'description',
        this.requestFormGroup.get('description')?.value
      );
    }

    if (this.requestFormGroup.get('due_date')?.value) {
      formData.append(
        'due_date',
        dayjs(this.requestFormGroup.get('due_date')?.value).format(
          'YYYY-MM-DD HH:mm:ss'
        )
      );
    }
    formData.append('client_id', this.requestFormGroup.get('client_id')?.value);
    formData.append(
      'contact_id',
      this.requestFormGroup.get('contact_id')?.value
    );

    const sectionGroups: FormGroup[] =
      (this.requestFormGroup.get('section_collections') as any)?.controls || [];

    sectionGroups.forEach((sectionGroup, sectionGroupIndex) => {
      const sectionGroupValue = sectionGroup.value;
      if (sectionGroupValue.id) {
        formData.append(
          `section_groups[${sectionGroupIndex}][id]`,
          sectionGroupValue.id
        );
      }

      if (sectionGroupValue.title) {
        formData.append(
          `section_groups[${sectionGroupIndex}][title]`,
          sectionGroupValue.title
        );
      }

      // handle sections
      const sections: FormGroup[] =
        (sectionGroup.get('sections') as any)?.controls || [];

      sections.forEach((section, sectionIndex: number) => {
        const sectionValue = section.value;
        if (sectionValue.id) {
          formData.append(
            `section_groups[${sectionGroupIndex}][sections][${sectionIndex}][id]`,
            sectionValue.id
          );
        }

        formData.append(
          `section_groups[${sectionGroupIndex}][sections][${sectionIndex}][title]`,
          sectionValue.title
        );
        if (sectionValue.description) {
          formData.append(
            `section_groups[${sectionGroupIndex}][sections][${sectionIndex}][description]`,
            sectionValue.description
          );
        }
        formData.append(
          `section_groups[${sectionGroupIndex}][sections][${sectionIndex}][max_file_count]`,
          sectionValue.max_file_count
        );

        // handle max file size ( also check the unit)
        if (sectionValue.file_size_unit === 'MB') {
          formData.append(
            `section_groups[${sectionGroupIndex}][sections][${sectionIndex}][max_file_size]`,
            (sectionValue.max_file_size * 1024).toString()
          );
        } else {
          formData.append(
            `section_groups[${sectionGroupIndex}][sections][${sectionIndex}][max_file_size]`,
            sectionValue.max_file_size
          );
        }

        if (sectionValue.selected_data_type) {
          formData.append(
            `section_groups[${sectionGroupIndex}][sections][${sectionIndex}][selected_data_type]`,
            sectionValue.selected_data_type
          );
        }

        // handle info files
        ((sectionValue.info_files as any[]) || []).forEach(
          (infoFile, infoFileIndex) => {
            if (infoFile.id) {
              formData.append(
                `section_groups[${sectionGroupIndex}][sections][${sectionIndex}][info_files][${infoFileIndex}][id]`,
                infoFile.id
              );
            }
            formData.append(
              `section_groups[${sectionGroupIndex}][sections][${sectionIndex}][info_files][${infoFileIndex}][title]`,
              infoFile.title
            );

            if (infoFile.description) {
              formData.append(
                `section_groups[${sectionGroupIndex}][sections][${sectionIndex}][info_files][${infoFileIndex}][description]`,
                infoFile.description
              );
            }

            if (infoFile.file) {
              formData.append(
                `section_groups[${sectionGroupIndex}][sections][${sectionIndex}][info_files][${infoFileIndex}][has_file]`,
                'true'
              );

              formData.append(
                `section_groups[${sectionGroupIndex}][sections][${sectionIndex}][info_files][${infoFileIndex}][file]`,
                infoFile.uploaded_file_blob
              );
            } else {
              formData.append(
                `section_groups[${sectionGroupIndex}][sections][${sectionIndex}][info_files][${infoFileIndex}][has_file]`,
                'false'
              );
            }
            if (infoFile.duplicate_from_info_file) {
              formData.append(
                `section_groups[${sectionGroupIndex}][sections][${sectionIndex}][info_files][${infoFileIndex}][duplicate_from_info_file]`,
                infoFile.duplicate_from_info_file
              );
            }
          }
        );

        // handle expected files
        ((sectionValue.expected_files as any[]) || [])
          .filter((f) => f.is_picked)
          .forEach((expectedFile, expectedFileIndex) => {
            if (expectedFile.id) {
              formData.append(
                `section_groups[${sectionGroupIndex}][sections][${sectionIndex}][expected_files][${expectedFileIndex}][id]`,
                expectedFile.id
              );
            }

            if (
              sectionValue.gen_expected_file_validations.validate_color_space
            ) {
              formData.append(
                `section_groups[${sectionGroupIndex}][sections][${sectionIndex}][expected_files][${expectedFileIndex}][color_space]`,
                sectionValue.gen_expected_file_validations.color_space
              );
            }

            if (
              sectionValue.gen_expected_file_validations.validate_resolution
            ) {
              formData.append(
                `section_groups[${sectionGroupIndex}][sections][${sectionIndex}][expected_files][${expectedFileIndex}][resolution]`,
                sectionValue.gen_expected_file_validations.resolution
              );
            }

            if (
              sectionValue.gen_expected_file_validations.validate_dimensions
            ) {
              formData.append(
                `section_groups[${sectionGroupIndex}][sections][${sectionIndex}][expected_files][${expectedFileIndex}][dimensions_height]`,
                sectionValue.gen_expected_file_validations.dimensions_height
              );
              formData.append(
                `section_groups[${sectionGroupIndex}][sections][${sectionIndex}][expected_files][${expectedFileIndex}][dimensions_width]`,
                sectionValue.gen_expected_file_validations.dimensions_width
              );
              formData.append(
                `section_groups[${sectionGroupIndex}][sections][${sectionIndex}][expected_files][${expectedFileIndex}][dimensions_bleed]`,
                sectionValue.gen_expected_file_validations.dimensions_bleed
              );
              formData.append(
                `section_groups[${sectionGroupIndex}][sections][${sectionIndex}][expected_files][${expectedFileIndex}][dimensions_unit]`,
                sectionValue.gen_expected_file_validations.dimensions_unit
              );
            }


            if (
              sectionValue.gen_expected_file_validations.proof_required
            ) {
              formData.append(
                `section_groups[${sectionGroupIndex}][sections][${sectionIndex}][expected_files][${expectedFileIndex}][proof_required]`,
                sectionValue.gen_expected_file_validations.proof_required
              );
            }

            // handle custom files
            if (
              expectedFile.is_custom_type !== null &&
              expectedFile.is_custom_type !== undefined
            ) {
              formData.append(
                `section_groups[${sectionGroupIndex}][sections][${sectionIndex}][expected_files][${expectedFileIndex}][is_custom_type]`,
                expectedFile.is_custom_type
              );
              formData.append(
                `section_groups[${sectionGroupIndex}][sections][${sectionIndex}][expected_files][${expectedFileIndex}][name]`,
                expectedFile.name
              );
            } else {
              formData.append(
                `section_groups[${sectionGroupIndex}][sections][${sectionIndex}][expected_files][${expectedFileIndex}][file_type_id]`,
                expectedFile.file_type_id
              );
            }
          });
      });
    });

    return formData;
  }
}
