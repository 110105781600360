import { Component, OnInit } from '@angular/core';
import { RequestViewPageService } from '../request-view-page.service';
import { ToastrService } from 'ngx-toastr';
import { TranslocoService } from '@ngneat/transloco';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-floating-navigator',
  templateUrl: './floating-navigator.component.html',
  styleUrls: ['./floating-navigator.component.scss'],
})
export class FloatingNavigatorComponent {
  constructor(
    public requestViewPageService: RequestViewPageService,
    private toastr: ToastrService,
    private translocoService: TranslocoService,
    private route: ActivatedRoute
  ) {}

  get showNavigator() {
    return !this.requestViewPageService.isAgency && !this.requestViewPageService.producerHash
  }

  toNextSection() {
    const currentIndex =
      this.requestViewPageService.selectedSectionMetaData?.globalSectionIndex ||
      0;
    this.requestViewPageService.setSelectedSection(
      this.requestViewPageService.globalSectionList[currentIndex + 1].section
    );
  }

  toPreviousSection() {
    const currentIndex =
      this.requestViewPageService.selectedSectionMetaData?.globalSectionIndex ||
      1;
    this.requestViewPageService.setSelectedSection(
      this.requestViewPageService.globalSectionList[currentIndex - 1].section
    );
  }

  doSubmit() {
    if (this.requestViewPageService.requestData?.request_status_id === 1) {
      return this.requestViewPageService.submit();
    }
    if (
      this.requestViewPageService.requestData?.request_status_id === 203 ||
      this.requestViewPageService.requestData?.request_status_id === 303
    ) {
      return this.requestViewPageService.submit();
    }

    const clientHash = this.route.snapshot.queryParamMap.get('client_hash');
    if (clientHash && this.requestViewPageService.requestData?.request_status_id === 2 ) {
      return this.requestViewPageService.submitSectionAsClient();
    } else if (this.requestViewPageService.requestData?.request_status_id === 2) {
      return this.requestViewPageService.submitAsAgent();
    }

    if (this.requestViewPageService.requestData?.request_status_id === 3) {
      return this.requestViewPageService.submitAsProducer();
    }

    this.toastr.error(
      this.translocoService.translate(`Action Not Implemented yet`)
    );
  }

  get navigatorDashOffset() {
    const total = this.requestViewPageService.globalSectionList.length;
    const current =
      (this.requestViewPageService.selectedSectionMetaData
        ?.globalSectionIndex || 0) + 1;

    return 80 - (current / total) * 80;
  }
}
