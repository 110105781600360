<app-custom-modal [modalEvents]="sendRequestModalService.modalEvents" *transloco="let t;" classes="max-w-5xl">
  <h1 header class="text-xl font-semibold">
    {{ sendRequestModalService.currentRequest?.title }}
  </h1>
  <div content-body>
    <form [formGroup]="sendRequestModalService.sendRequestForm">
      <div class="w-full">
        <label
          for="message"
          class="block mb-2 text-xs 2xl:text-sm font-medium text-gray-900 dark:text-white"
          transloco="Message"
        >Message</label
        >
        <textarea
          id="message"
          rows="6"
          formControlName="message"
          class="border border-gray3 rounded-lg focus:border-gray15 focus:ring-1 focus:ring-gray15 focus:outline-none p-3 text-sm w-full font-Satoshi text-15px font-medium text-primary"
          [placeholder]="t('Enter your message...')"
        ></textarea>
      </div>
      <div class="w-full mt-4">
        <label
          class="block mb-2 text-xs 2xl:text-sm font-medium text-gray-900 dark:text-white"
          for="recipient"
          transloco="Add Recipients"
        >Add Recipients</label
        >
        <select
          id="recipient"
          class="border border-gray3 rounded-lg focus:border-gray15 focus:ring-1 focus:ring-gray15 focus:outline-none p-3 text-sm w-full font-Satoshi text-15px font-medium text-primary"
          [formControl]="contactSelectInput"
        >
          <option disabled [value]="''" selected transloco="Select">
            Select
          </option>
          <option
            *ngFor="
                    let contact of sendRequestModalService.remainingContactList
                  "
            [value]="contact.email"
          >
            {{ contact.first_name }} {{ contact.last_name }} -
            {{ contact.email }}
          </option>
        </select>
      </div>
      <div class="flex flex-wrap gap-4 py-2">
        <div
          *ngFor="let email of emailList"
          class="bg-white px-4 py-2 rounded-lg border-2"
        >
          {{ email }}
          <button
            class="p-2"
            (click)="sendRequestModalService.removeRecipient(email)"
          >
            x
          </button>
        </div>
      </div>
    </form>
  </div>
  <div footer class="flex justify-between w-full">
    <button
      class="tw-btn-ghost bg-white"
      type="button"
      (click)="closeModal()"
      transloco="Cancel"
    >
      Cancel
    </button>
    <button
      class="tw-btn-secondary"
      type="button"
      (click)="sendRequestModalService.handleSubmit()"
      transloco="Send"
    >
      Send
    </button>
  </div>
</app-custom-modal>

