import { Pipe, PipeTransform } from '@angular/core';

/**
 * @description
 *
 * Formats a value according to digit options and locale rules.
 * Locale determines group sizing and separator,
 * decimal point character, and other locale-specific configurations.
 *
 *
 * @usageNotes
 *
 * ### digitsInfo
 *
 * The value's decimal representation is specified by the `digitsInfo`
 * parameter, written in the following format:<br>
 *
 * ```
 * {minIntegerDigits}.{minFractionDigits}-{maxFractionDigits}
 * ```
 *
 *  - `minIntegerDigits`:
 * The minimum number of integer digits before the decimal point.
 * Default is 1.
 *
 * - `minFractionDigits`:
 * The minimum number of digits after the decimal point.
 * Default is 0.
 *
 *  - `maxFractionDigits`:
 * The maximum number of digits after the decimal point.
 * Default is 3.
 *
 * If the formatted value is truncated it will be rounded using the "to-nearest" method:
 *
 * ```
 * {{3.6 | number: '1.0-0'}}
 * <!--will output '4'-->
 *
 * {{-3.6 | number:'1.0-0'}}
 * <!--will output '-4'-->
 * ```
 * ### isCurrency
 *
 * make this true if you want special formatting for empty decimals
 *
 * ### locale
 *
 * `locale` will format a value according to locale rules.
 * Locale determines group sizing and separator,
 * decimal point character, and other locale-specific configurations.
 *
 *
 *
 */
@Pipe({
  name: 'nativeNumber',
})
export class NativeNumberPipe implements PipeTransform {
  transform(
    value: number | string,
    digitsInfo?: string,
    isCurrency = false,
    locale: string = navigator.language
  ): string {
    if (value == null) {
      return '';
    }

    const numericValue = typeof value === 'string' ? parseFloat(value) : value;

    // Parse digitsInfo: "minInteger.minFraction-maxFraction"
    const [integerPart, fractionPart] = digitsInfo?.split('.') || [];
    const minIntegerDigits = parseInt(integerPart || '1', 10);
    const [minFractionDigits, maxFractionDigits] = (fractionPart || '0-3')
      .split('-')
      .map((val) => parseInt(val, 10));

    const formatter = new Intl.NumberFormat(locale, {
      minimumIntegerDigits: minIntegerDigits,
      minimumFractionDigits: minFractionDigits,
      maximumFractionDigits: maxFractionDigits,
    });

    let formattedValue = formatter.format(numericValue);
    console.log(formattedValue);
    if (
      isCurrency &&
      minFractionDigits === 2 &&
      maxFractionDigits === 2 &&
      formattedValue.endsWith('00')
    ) {
      return formattedValue.slice(0, formattedValue.length - 2) + '-';
    }

    return formattedValue;
  }
}
